
.LegislationCard{

    width: 100%;
    justify-self: center;
    font-family: Roboto, sans-serif;
    display: grid;
    grid-template: 0.40fr 1fr / 0.75fr 0.25fr 0.25fr 3fr 0.50fr;
}

.LegislationCard__line{
display: grid;
grid-template-rows: 0.25fr 1fr;
background-color: white;
}

.LegislationCard__point{
    grid-column: 3/4;
    content: '';
    left: 50%;
    top: 8px;
    width: 9px;
    height: 9px;
    background: #0082ca;
    -webkit-border-radius: 100%;
    -moz-border-radius: 100%;
    -ms-border-radius: 100%;
    border-radius: 100%;
    -webkit-transform: translateX(-50%);
    -moz-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    -o-transform: translateX(-50%);
    transform: translateX(-50%);
}

.LegislationCard__lin{
    background-color: gray ;
    width: 1px;
    margin-left: 100px;
}

.LegislationCard__date{
    grid-column: 2/3;
    justify-self: self-end;
}

.LegislationCard__tittle{
    justify-self: left;
    grid-column: 4/5;
}

.LegislationCard__body{
    text-align: left;
    grid-column: 4/5;
}

