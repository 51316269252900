/* Client List component */
.client-list {
  padding-top: 5.5rem;
  /* display: grid;
  grid-template: 1fr / minmax(100px, 1200px);
  justify-content: center; */
  list-style-type: none;
  margin: 20px auto;
  padding: 0;
  /*Flexbox setup */
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  margin: 5px 2px;
}

.client-list__title {
  width: 90%;
  justify-self: center;
  padding-bottom: 30px;
  padding-top: 30px;
}

/* Client Cards of Client list component*/
.client-list__cards {
  /* width: 90%;
  justify-self: center;
  display: grid;
  grid-template: repeat(1,auto)/ repeat(4, 200px);
  justify-content: center; */
  /* padding: 6.5em 2.5rem; */
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  padding: 0;
  margin: 5px 2px;
  


}

@media screen and (max-width : 900px){
  .client-list__cards {
    /* grid-template: repeat(1,auto)/ repeat(1, 150px 150px); */

  }
  .title_title--large{
    font-size: 4rem;
  }
  
}