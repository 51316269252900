.FormPreguntas{
    display: grid;
    grid-row: auto;
    /* justify-content: center; */
    }
    
  /* Elemento Radio, cuando está marcado */
  .FormPreguntas input[type="radio"]:checked {
    box-shadow: 0 0 0 3px red;
  }
  .span {
   font-weight: bold;
  }
.FormPreguntas__bloque1{
    display: grid;
    
}
/* Etiquetas para entradas marcadas */
input:checked{
    color: red;
  }
  

.FormPreguntas textarea{
 border-color: #d92530;
 border:4px solid #d92530;
 width: 90%;
 
}

.FormPreguntas_bloque1-columnas{
display: grid;
grid-template: repeat(1,minmax(auto,1fr)) / repeat(2,minmax(200px,1fr));
       
}
.FormPreguntas_bloque1-filas{
    display: grid;
    grid-template: repeat(2,minmax(auto,1fr)) / repeat(1,minmax(auto,1fr));
    color: #d92530;
    justify-items: center;
}
.FormPreguntas_bloque1-filas input{
    height: 18px;
    width: 18px;
}

.FormPreguntas_bloque1-1columnas{
    display: grid;
    grid-template: repeat(1,minmax(auto,1fr)) / repeat(1,minmax(200px,1fr));
    
    }
.FormPreguntas_bloque1-5columnas{
        display: grid;
        grid-template: repeat(1,minmax(auto,1fr)) / repeat(5,minmax(auto,1fr));
        gap:4px;
        
        }
 .FormPreguntas_bloque1-3columnas{
    display: grid;
    grid-template: repeat(1,minmax(auto,1fr)) / 80px 2fr 80px;
    gap:20px;
 }
.FormPreguntas button{
        width: 135px;
        height: 36px;
        background: #d92530;
        border: #d92530;
        color: #ffffff;
        font-size: 16px;
        float: right;
        margin: 10px ;
    }
    .FormPreguntas a{
        text-decoration: none;
        height: 29px;
        width: auto;
        background: #d92530;
        border: #d92530;
        color: #ffffff;
        font-size: 16px;
        float: right;
        display: inline-block;
        padding-top: 8px;
        padding-left: 15px;
        padding-right: 15px;
        color: #fff;
    
    }
@media screen and (max-width : 900px){
    /* .FormPreguntas{
        
    } */
    .FormPreguntas p{
        font-size: 12px;
        margin: 10px;
        
    }
    .FormPreguntas_bloque1-columnas{
        grid-template: repeat(1,minmax(auto,1fr)) / repeat(1,minmax(200px,1fr));
        font-size: 12px;
    }
    .FormPreguntas_bloque1-1columnas{
        display: grid;
        grid-template: repeat(1, minmax(40px, 1fr)) /auto;
        font-size: 12px;
    }
    .FormPreguntas_bloque1-3columnas{
        grid-template: repeat(1,minmax(auto,1fr)) / repeat(1,minmax(200px,1fr));
        font-size: 12px;
    }
    
    .FormPreguntas_bloque1-5columnas{
        display: grid;
        grid-template: repeat(1,minmax(auto,1fr)) / repeat(5,minmax(auto));
        gap:4px;
        font-size: 12px;
        
        }
}