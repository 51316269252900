.solutionsCard{
    padding: 0.63rem;
    background: #FFFFFF;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.10);
    border-radius: 4px 4px 4px 4px;
    overflow: hidden;
    position: relative;
    border: solid;
    border-color: #d92530;
    border-radius:5%;
    width: 500px;
    margin: 25px;
}
.solutionsCard__image {

    justify-content: center;
    align-content: center;
    align-items: center;
    /* display: grid;
    grid-template: 1fr / 50%; */
    display: flex;
}
.solutionsCard_seccionTitle{

    font-size: 1.8rem;
    font-weight: bold;
    font-family:  'Open Sans', 'Roboto',sans-serif;
}

.solutionsCard__seccionInformacion ul li{
    font-size: 1.7rem;
}
.solutioncard__image{
    /* width: 100%; */
}
@media screen and (max-width: 450px){
    .solutionsCard{
     width: 250px;
    }
    .Card_Solutions__info {
        /* grid-template: 1fr / 35%; */
        display: flex;
        flex-direction: column;
    }
  }
