.contactus__top{
    padding-top: 5.5rem;
}
.contactus{
    display: grid;
    grid-template: repeat(3, minmax(200px 1fr)) / repeat(1, minmax(200px 1fr));
    gap: 1.75rem;
    justify-content: center;
    font-size:16px;
}

.contactus h1{
    padding-top: 2%;
}
.contactus__hrline{
    background-color: #0082ca;
    height: 5px;
    width: 60px;
    margin-left: 0px;
    margin-right: 0px;
    
}