.FormDownload{
  /* display: grid;
  grid-template: repeat(1, minmax(auto)) / repeat(1, minmax(auto));
  gap: 1.75rem; */
  display: flex;
  flex-direction: column ;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  /* width: 1000px; */
  font-size:16px;
}

.FormDownload h1{
padding-top: 2%;
}
.FormDownload input{
  border-radius:8px 8px 8px 8px;
  font-size: 13px;
  line-height: 18px;
  background: #d1e8f5; 
  border-radius: 5px !important;
  border: 1px solid #0082ca8a; 
  box-shadow: none; 
  outline: none; 
  padding: 16px 30px 15px; 
  transition: all 0.3s ease; 
  box-sizing: border-box !important;
  width: 98%;
  display: block;
  height: 45px;
  margin:15px; 
}
.FormDownload select{
   
border-radius:8px 8px 8px 8px;
font-size: 13px;
line-height: 18px;
border-radius: 5px !important;
border: 1px solid #0082ca8a; 
box-shadow: none; 
outline: none; 
padding: 16px 30px 15px; 
transition: all 0.3s ease; 
box-sizing: border-box !important;
width: 80%;
display: block;
height: 49px;
margin:15px;

}

.buttonPDF_a{
color: #fff;
text-decoration: none;
display: inline-block;
padding-top: 10px;
padding-left: 8px;
padding-right: 8px;
border-radius: 5px;
background-color: #d92530;
height: 29px;
width: 132px;
margin:10px;
font-size: 15px;
}
.buttonPDF_normal{
color: #fff;
text-decoration: none;
display: inline-block;
padding-top: 10px;
padding-left: 8px;
padding-right: 8px;
border-radius: 5px;
background-color: #FF4544;
height: 29px;
width: auto;
margin:10px;
font-size: 15px;
}
.FormDownload label{
font-family: 'Open Sans',Roboto;
font-weight: bold;
}
.FormDownload__button{
color: #fff;
display: inline-block;
padding: 5px 5px;
border-radius: 5px;
background-color: #d92530;
border: none;
height: 40px;
width: auto;
margin: 10px;
font-size:14px;
}
.FormDownload__buttonMovil{
color: #fff;
display: inline-block;
padding: 5px 5px;
border-radius: 5px;
background-color: #d92530;
border: none;
height: 40px;
width: auto;
margin: 10px;
font-size:14px;
}
.butonPDF{
background:#d92530;
border-radius: 8px 8px 8px 8px;
color:#fff;
display:inline-block;
font-family:'Helvetica Neue',Helvetica,Arial,sans-serif;
height: 40px;
text-align:center;
text-decoration:none;
width:auto!important
}
.FormDownload__comprobantes{
      /* display: grid; 
      grid-template: repeat(1,200px)/ repeat(3,auto); 
      gap:5rem; */
display: flex;
flex-direction: row wrap;
justify-content: center;
list-style-type: none;
align-content: space-between;
     
}

.FormDownload__input{
  /* display: grid; 
  grid-template: repeat(1,auto)/ repeat(2,auto);  */
display: flex;
flex-direction: row wrap;
}

.FormDownload__columnas{
display:flex;
flex-direction: row wrap;
justify-content: center;
align-content: space-between;
/* margin: 0 auto; */

}
.FormDownload__image{
height: 500px;
width: 300px;

}
/*popover--------*/

/* Popover styling */

a {
text-decoration: none;
}

.popover__title {
/* font-size: 24px; */
line-height: 36px;
text-decoration: none;
/* color: rgb(228, 68, 68); */
text-align: center;
padding: 15px 0;
}

.popover__wrapper {
position: relative;
/* margin-top: 1.5rem; */
display: inline-block;
}
.popover__content {
opacity: 0;
visibility: hidden;
position: absolute;
left: -150px;
transform: translate(0, 10px);
background-color: #ffffff;
padding: 1.5rem;
box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26);
width: auto;
}
.popover__content:before {
position: absolute;
z-index: -1;
content: "";
right: calc(50% - 10px);
top: -8px;
border-style: solid;
border-width: 0 10px 10px 10px;
border-color: transparent transparent #bfbfbf transparent;
transition-duration: 0.3s;
transition-property: transform;
}
.popover__wrapper:hover .popover__content {
z-index: 10;
opacity: 1;
visibility: visible;
transform: translate(0, -20px);
transition: all 0.5s cubic-bezier(0.75, -0.02, 0.2, 0.97);
}
.popover__message {
text-align: center;
}

/*---------------------------------*/
/*------Modal-----*/
#modal {
  left: 50%;
  margin: -250px 0 0 -32%;
  opacity: 0;
  position: absolute;
  top: -50%;
  visibility: hidden;
  width: 65%;
  box-shadow: 0 3px 7px rgba(0,0,0,.25);
  box-sizing: border-box;
  transition: all .4s ease-in-out;
  -moz-transition: all .4s ease-in-out;
  -webkit-transition: all .4s ease-in-out
}

#modal:target {
  opacity: 1;
  top: 50%;
  visibility: visible
}
#modal .header, #modal .footer {
  border-bottom: 1px solid #fff;
  border-radius: 5px 5px 0 0
}

#modal .footer {
  border: none;
  border-top: 1px solid #e7e7e7;
  border-radius: 0 0 5px 5px
}

#modal h2 {
  margin: 0;
  color: #fff
}

#modal .btn { float: right }

#modal .copy, #modal .header, #modal .footer {
  padding: 10px;
  color: black
}

.modal-content {
  background: #fff;
  position: relative;
  z-index: 20;
  border-radius: 5px;
  color:black;
}

#modal .copy { background: #fff}

#modal .overlay {
  background-color: #000;
  background: rgba(0,0,0,.8);
  height: 100%;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 10
}

.copy a {
  color: #fff;
  text-decoration: none;
  padding: 5px 10px;
  border-radius: 5px;
  background-color: #E74C3C;
  margin: 10px;
  justify-content: right;
  cursor: help;
}
.video{
  display: flex;
  flex-direction: column ;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
}
.video iframe{
  width:550px;
  height:500px;
}


@media screen and (max-width : 900px){
  .FormDownload {
    grid-template: repeat(1, minmax(auto, 1fr)) / 1fr;
    gap: 0.8rem;
    margin-left: 10px;
  }
  .FormDownload__comprobantes{
      display: grid; 
      grid-template: auto/ auto; 
      gap:1rem;
      /* display: flex;
      flex-direction: column; */
      margin-bottom: 10px;
  }
  .FormDownload__input{
      display: grid; 
      grid-template: auto/auto; 
      /* display: flex;
      flex-direction: column; */
    
  }
  .FormDownload  button{
    width: 140px;
  }
  .video iframe{
    width:100%;
    height:300px;
  }
  
}

@media screen and (min-width: 320px) and (max-width:568px) {

.FormDownload {
  grid-template: repeat(1, minmax(auto, 1fr)) / 1fr;
  gap: 0.8rem;
  margin-left: 10px;
}
.FormDownload__comprobantes{
    display: grid; 
    grid-template: auto/ auto; 
    gap:1rem;
    /* display: flex;
    flex-direction: column; */
    margin-bottom: 10px;
}
.FormDownload__input{
    display: grid; 
    grid-template: auto/auto; 
    /* display: flex;
    flex-direction: column; */
  
}
.FormDownload  button{
  width: 140px;
}
.video iframe{
  width:100%;
  height:300px;
}
.FormDownload input{

  width: 80%;
}

}