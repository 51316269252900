.novedades{
    display:grid;
    grid-template-rows: 200px 1fr;
    gap: 30px;
    padding: 4em 1rem;
    justify-content: center;
    margin: 0;
}
.novedades_visualizacion{

  display: grid;
  grid-template: repeat(1, minmax(200px, 1fr)) /repeat(2, minmax(200px, 400px));
  justify-content: center;
  gap:10px;
}
   .novedades table {
    border-collapse: collapse;
    width: 100%;
  }
  
  .novedades th, td {
    text-align: left;
    padding: 8px;
  }
  
  .novedades tr:nth-child(even){background-color: #f2f2f2}
  
  .novedades th {
    background-color: #d92530;
    color: white;
  }

  @media (min-width: 900px){
    .novedades_visualizacion{
      grid-template: repeat(1, minmax(200px, 1fr)) / 1fr;
     
    }
  }