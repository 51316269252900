@import url('https://fonts.googleapis.com/css?family=Open+Sans&display=swap');
@import url('https://fonts.googleapis.com/css?family=Roboto&display=swap');
footer {
  /* display: grid;
  grid-template:1fr auto auto auto / minmax(200px, 1200px); */
  display: flex;
  flex-direction: column;
  background: #1c212f;
  width: 100%;
  /* justify-content: center; */
  justify-content:space-around;
  align-self: center;
  padding-right: 0;
}

.footer_info {
  /* grid-template: repeat(1,auto)/ repeat(1, 380px 250px 490px); */
  /* display: grid;
  grid-template: repeat(1,auto)/ repeat(3,auto);
  gap:5rem; */
  display: flex;
  flex-direction: row wrap;
  /* justify-content: center; */
  justify-content: space-around;
  width: 990px;
  margin: 0 auto;
  /* margin: 20px; */

}

.footer__servicios{
  /* grid-template: repeat(1,auto)/repeat(1,245px 245px); */
  /* display: grid;
  grid-template: repeat(1,auto)/repeat(2,auto);
  gap:1.5rem; */
  display: flex;
  flex-direction: row wrap;
  /* justify-content: center; */
  justify-content: space-around;

}
.footer__servicios_Margin{
  margin-right: 10px;

}

.footer__servicios p{
  color: #808495;
  font-size: 1rem;
}

.footer_info_subtitulos{

  color: white;
  font-family:  'Open Sans', 'Roboto',sans-serif;

}
/* .footer_info h4 {
  margin: .6em 0;
  color: white;
  font-family:  'Open Sans', 'Roboto',sans-serif;
  font-size: 1rem;

} */
.footer_info a {
  margin: .6em 0;
  color: white;
  font-family:  'Open Sans', 'Roboto',sans-serif;
  font-size: 1rem;
  text-decoration: none;
}
.footer hr{
  background-color: #69B4CA;
  height: 3px;
  /* margin-left: 0%; */
  /* width: 100% */
  width: 50px;
}
.footer_info_hr{
  background-color: #69B4CA;
  /* margin-left: 0%; */
  /* width: 100% */
  width: 990px;
}

.footer_info hr{
    background-color: #69B4CA;
    height: 2px;
    margin-left: 0%;
    width:9rem;
}
.footer_info p{
  color: #808495;
  font-size: 1rem;
}
.logo__Footer {
    margin-block-start: 14.940px;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    width:150px;
    height: 50px;
}
.social{
  padding:  0.3125rem;
  margin: 0 auto;
}
.copyrigth{
  margin: .6em 0;
  font-family:  'Open Sans', 'Roboto',sans-serif;  font-size: 1rem;
  text-align: center;
  align-self: center;

}
.copyrigth p{
  color: #808495;
}


/* Smartphones (portrait and landscape) ----------- */  

@media screen and (device-width: 320px) and (device-height: 568px) {

  .footer_info {
    display: grid;
    grid-template: repeat(1, minmax(auto, 1fr)) / 1fr;
    gap: 0.8rem;
    width: 300px;
  }
  .footer_info hr{
    background-color: #69B4CA;
    height: 2px;
    margin-left: 0%;
    width:7.5rem;
  }
  .footer__servicios_Margin{
    margin-right: 5px;
  }
  .footer__servicios{
    display: grid;
    grid-template: repeat(1, minmax(auto, 1fr)) / 1fr;
    gap: 0.8rem;
  
  }
  .footer_info p{
    color: #808495;
    font-size: 1.6rem;
  }
  .footer_info_hr{
    background-color: #69B4CA;
    /* margin-left: 0%; */
    /* width: 100% */
    width: 300px;
  }
  .footer_info_subtitulos{
    font-size: 1.7rem;
  }
  .footer_info a {
    font-size: 1.7rem;
  }
  .copyrigth p{
    font-size:1.5rem ;
  }
  .logo__Footer {
    width: 140px;
    height: 20px;
  }
  .fa-3x {
    font-size: 5em;
  }
}

/* 
  ##Device = Tablets, Ipads (portrait)
  ##Screen = B/w 768px to 1024px
*/

@media (min-width: 768px) and (max-width: 1024px) {
  .footer_info {
    display: grid;
    grid-template: repeat(1, minmax(auto, 1fr)) / repeat(3,1fr); 
    width: 700px;
  }
  .footer__servicios_Margin{
    margin-right: 5px;
  }
  .footer_info hr{
    background-color: #69B4CA;
    height: 2px;
    margin-left: 0%;
    width:7.5rem;
  }
  .footer__servicios{
    display: grid;
    grid-template: repeat(1, minmax(auto, 1fr)) / repeat(2,1fr);
    gap: 0.8rem;
  
  }
  .footer_info_hr{
    background-color: #69B4CA;
    /* margin-left: 0%; */
    /* width: 100% */
    width: 500px;
  }
  .footer_info p{
    color: #808495;
    font-size: 1.6rem;
  }
  
  .footer_info_subtitulos{
    font-size: 1.7rem;
  }
  .footer_info a {
    font-size: 1.7rem;
  }
  .copyrigth p{
    font-size:1.5rem ;
  }
  .logo__Footer {
    width: 140px;
    height: 20px;
  }
  .fa-3x {
    font-size: 5em;
  }
  
}

/* 
  ##Device = Tablets, Ipads (landscape)
  ##Screen = B/w 768px to 1024px
*/

@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  
  .footer_info {
    display: grid;
    grid-template: repeat(1, minmax(auto, 1fr)) / 1fr;
    gap: 0.8rem;
    
  }
  .footer__servicios_Margin{
    margin-right: 5px;
  }
  .footer_info hr{
    background-color: #69B4CA;
    height: 2px;
    margin-left: 0%;
    width:7.5rem;
  }
  .footer__servicios{
    display: grid;
    grid-template: repeat(1, minmax(auto, 1fr)) / 1fr;
    gap: 0.8rem;
  
  }
  .footer_info p{
    color: #808495;
    font-size: 1.6rem;
  }
  
  .footer_info_subtitulos{
    font-size: 1.7rem;
  }
  .footer_info a {
    font-size: 1.7rem;
  }
  .copyrigth p{
    font-size:1.5rem ;
  }
  .logo__Footer {
    width: 140px;
    height: 20px;
  }
  .fa-3x {
    font-size: 5em;
  }
  
}

/* 
  ##Device = Low Resolution Tablets, Mobiles (Landscape)
  ##Screen = B/w 481px to 767px
*/

@media (min-width: 481px) and (max-width: 767px) {
  .footer__servicios_Margin{
    margin-right: 5px;
  }
  .footer_info {
    display: grid;
    grid-template: repeat(1, minmax(auto, 1fr)) / 1fr;
    gap: 0.8rem;
    width: 300px;
  }
  .footer_info hr{
    background-color: #69B4CA;
    height: 2px;
    margin-left: 0%;
    width:7.5rem;
  }
  .footer__servicios{
    display: grid;
    grid-template: repeat(1, minmax(auto, 1fr)) / 1fr;
    gap: 0.8rem;
  
  }
  .footer_info p{
    color: #808495;
    font-size: 1.6rem;
  }
  .footer_info_hr{
    background-color: #69B4CA;
    /* margin-left: 0%; */
    /* width: 100% */
    width: 400px;
  }
  .footer_info_subtitulos{
    font-size: 1.7rem;
  }
  .footer_info a {
    font-size: 1.7rem;
  }
  .copyrigth p{
    font-size:1.5rem ;
  }
  .logo__Footer {
    width: 140px;
    height: 20px;
  }
  .fa-3x {
    font-size: 5em;
  }
  
}

/* 
  ##Device = Most of the Smartphones Mobiles (Portrait)
  ##Screen = B/w 320px to 479px
*/

@media (min-width: 320px) and (max-width: 480px) {
  .footer__servicios_Margin{
    margin-right: 5px;
  }
  .footer_info {
    display: grid;
    grid-template: repeat(1, minmax(auto, 1fr)) / 1fr;
    gap: 0.8rem;
    width: 300px;
  }
  .footer_info hr{
    background-color: #69B4CA;
    height: 2px;
    margin-left: 0%;
    width:7.5rem;
  }
  .footer__servicios{
    display: grid;
    grid-template: repeat(1, minmax(auto, 1fr)) / 1fr;
    gap: 0.8rem;
  
  }
  .footer_info_hr{
    background-color: #69B4CA;
    /* margin-left: 0%; */
    /* width: 100% */
    width: 300px;
  }
  .footer_info p{
    color: #808495;
    font-size: 1.6rem;
  }
  
  .footer_info_subtitulos{
    font-size: 1.7rem;
  }
  .footer_info a {
    font-size: 1.7rem;
  }
  .copyrigth p{
    font-size:1.5rem ;
  }
  .logo__Footer {
    width: 140px;
    height: 20px;
  }
  .fa-3x {
    font-size: 5em;
  }
  
}

