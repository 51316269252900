.FormEmObligada{
   /* margin-top: 9rem; */
   margin: 8rem 1rem;
   display: grid;
   justify-content: center;
}
.FormEmObligada h1{
   text-align:center;
}
.FormEmObligada__2Columnas{
  
   display: grid;
   grid-template: repeat(1,auto)/ 1fr 1fr; 
   
}
.FormEmObligada input{
   background: #d1e8f5;
   border-radius: 5px!important;
   border: 1px solid rgba(0,130,202,.54);
   padding: .375rem .75rem;
   width: 80%;
   font-size: 1.2rem;
   line-height: 18px
}
.FormEmObligada label{
   font-weight: bold;
}

.FormEmObligada__button{
   font-size: 1rem;
   color: white;
   height: 30px;
   width: 90px;
   font-weight: bold;
   text-align: center;
   background: #d92530;
   border: #d92530 1px solid;
   border-radius: 5px;
}


@media screen and (max-width : 900px){
   .FormEmObligada label{
     font-size: 12px;
   }
   .FormEmObligada__2Columnas{
  
      display: grid;
      grid-template: repeat(1,auto)/ 1fr; 
      gap: 5px;
      justify-content: center;
   }
  
   .FormEmObligada input{
      
      font-size:14px;
  
   }
   .FormEmObligada__button{
      font-size: 12px;
     
   }
   
  
}