.comprobante{
    background: #333;
    color: white;
    padding: 1.2em;
    /* display: grid;
    grid-template: 1fr  / repeat(2,1fr); */
    justify-items: center;
    align-items: center;
    display: flex;
    flex-direction: row wrap;
    margin: 0 auto;
    justify-content: space-around;
}
.btn {
    background: #d92530;
    text-decoration: none;
    text-transform: uppercase;
    border: none;
    color: white;
    padding: 9px 30px;
    font-size: 1.5em;
    -webkit-transition-duration: 0.4s;
    transition-duration: 0.4s;
    cursor: pointer;
    font-family: 'Open Sans',Roboto;
    font-weight: bold;
    border-radius: 5px;
}
.comprobante__text{
    font-family: Roboto , sans-serif;
    font-size: 1.2em;
    font-weight: bold;
    color: white;
    margin-right: 10px;
}

@media screen and (max-width: 600px) {
    .comprobante__text{
        font-size: 10px;
    }
    .btn {
        padding: 8px 6px;
    }
    .comprobante{
        grid-template: 1fr / 62% 1fr;
    }
    
}