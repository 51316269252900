.PagEncuesta{
    margin: 130px 0 10px;
    display: grid;
    justify-content: center;
   
}
.PagEncuesta__FormEncuesta{
    display: grid;
    grid-template: repeat(1,minmax(auto,3fr)) / repeat(1,minmax(200px,1fr));
    align-self: center;
}
.body{
    margin-top: 70px;
}

 @media screen and (max-width : 900px){
    .PagEncuesta{
        margin: 50px 0 0;
       
    }
 }

