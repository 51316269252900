.static-header{
    display: flex;
    flex-direction: row;
    /* display: grid;
    grid-template: 1fr / 70%; */
    justify-content: center;
    background-color: #d92530;
    margin: 0 auto;
}
.static-header-menulist{
    /* display: grid;
    grid-template: 1fr / 1fr 1fr 1fr 15%; */
    display: flex;
    flex-direction: row;
    padding: 0;
    gap: 2px;
    list-style: none;
    color: white;
}
.static-header-hidden{
    display: none;
}
.static-header-menulist a {
    text-decoration: none;
    font-weight: bold;
    color: white;
    margin-right: 30px;
}
@media screen and (max-width: 900px) {
    .static-header{
        /* display: flex; */
        /* flex-direction: row; */
        display: grid;
        grid-template: 1fr / 95%;
        height: 50px;
        background-color: ##d92530;
    }
    .static-header-menulist{
        grid-template: 1fr 1fr / 1fr 1fr;
        /* display: flex;
        flex-direction: row; */
        justify-self: center;
        padding: 0;
        column-gap: 10px;
    }
    .static-header-menulist-FontAwesomeIcon{
        padding-top: 16px;
        padding-bottom: 16px;
    }
    .static-header-menulist a {
        font-size: 12px;
        padding-top: 16px;
        padding-bottom: 16px;
    }
    .phone{
        grid-row: 2/3;
        /* display: flex; */
        justify-self: center;
    }
    .phoneIcon{
        /* display: flex; */
        grid-column: 2/3;
        justify-self: center;
        align-self: flex-end;
    }
    .downloadVoucherIcon{
        /* display: flex; */
        grid-column: 1/2;
        justify-self: center;
        align-self: flex-end;
    }
    .downloadVoucher{
        /* display: flex; */
        justify-self: center;
        grid-row: 2 / 3;
    }
  }
