.nosotros{
  /* display: grid; */
  display: flex;
  flex-direction: column;
  padding: 4em 1rem;
  margin: 0;
  background-image: url("./SolutionBackground.png");
  background-size:100% 100%;
}
.nosotros-list-solutions{
  /* display: grid;
  grid-template: repeat(1, minmax(200px, 1fr)) / repeat(3, minmax(200px, 300px));
  gap: 1.75rem; */
  /* display: flex;
  flex-direction: row;
  justify-content: center; */
   margin: 0 auto;
   display: flex;
   width: 990px;
   flex-flow: row wrap;
   justify-content: space-around;
}

.nosotros__p{
  text-align: center;
  font-weight: bold;
  font-size: 1.5rem;
  max-width: 1200px;
  margin: 0 auto;
  padding-block-start: 20px;
  padding-block-end: 30px;
  width: 94%;
}
@media screen and (max-width : 1601px){
  .nosotros{
    display: grid;
    /* display: flex;
    flex-direction: column; */
    padding: 4em 1rem;
    background-image: none;
    background-size:100% 100%;
  }
  .nosotros__p{
    max-width: 900px;
  }
}
@media screen and (max-width : 900px){
  .nosotros-list-solutions {
    /* display: flex;
    flex-direction: column; */
    width: 300px;
    display: grid;
    grid-template: repeat(1, minmax(200px, 1fr)) / 1fr;
  }
  .nosotros__p{
    font-size: 1.7rem;
    max-width: 300px;
  }
}
@media screen and (device-height: 1024px) and (max-device-width: 768px)
 { 
  .nosotros-list-solutions{
    display: grid;
    grid-template: repeat(1, minmax(200px, 1fr)) / repeat(2,auto);
    justify-content: center;
    align-items: center;
  }
  .nosotros__p{
    font-size: 1.7rem;
    max-width: 600px;
  }


} 
/* @media (max-device-width: 768px) {
  .nosotros-list-solutions{
    display: grid;
    grid-template: repeat(1, minmax(200px, 1fr)) / repeat(2,auto);
    justify-content: center;
    align-items: center;
  }
  .nosotros__p{
    font-size: 1.7rem;
    max-width: 600px;
  }

} */