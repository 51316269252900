.form__voucher{
    padding-top: 5.5em;
}
/* .form__voucher img{
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 50%;
} */

@media screen and (max-width:750px){
    .form__voucher img{
       width: 310px;
       height: 350px;
    }
}