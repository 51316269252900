
.script{
    padding-top: 5.5rem;
    display: grid;
    overflow-x: hidden;
    overflow-y: hidden;
    grid-template: 1fr / minmax(100px, 1204px);
    justify-content: center;

}
.script-card{
    margin: 105px auto 35px;
    border-radius: 12px;
    box-shadow: 0 0 8px 0 rgba(0,0,0,.2);
    background-color: var(--white);
    padding: 30px;
    line-height: 1.5;
}

.script-card span{
    font-weight: bold;
}

.script h1{
    padding-top: 2%;
}
