.blogmore-section{
    display: grid;
    grid-template: 1fr / 1fr;
    justify-content: center;
}
.blogmore-section__body{
    display: grid;
    grid-template: repeat(4,auto) / 1200px;
    padding-top: 8.5em;
    justify-content: center;
    justify-items: left;
}
.blogmore-section__body h1{
    text-align: left;
    font-size: 1.8vw;
    padding-top: 2%;
}
.blogmore-section-body__image-description__date{
    display: grid;
    grid-template: 1fr / 20% 1fr;
    justify-items: left;
    align-items: center;
}

.blogmore-section-body__description{
    font-size: 1.2vw;
    text-align: justify;
    width: 100%;
}
.blogmore-section-body__button{
    background: #23527c;
    text-transform: uppercase;
    border: none;
    color: white;
    padding: 16px 32px;
    font-size: 16px;
    -webkit-transition-duration: 0.4s;
    transition-duration: 0.4s;
    cursor: pointer;
}
.blogmore-section-body__image-adapt{
    width: 50%;
}
@media screen and (max-width: 600px) {
    .blogmore-section{
        display: grid;
        grid-template: 1fr / 1fr;
        justify-content: unset;
    }

    .blogmore-section-body__image-description{
        display: grid;
        width: 26%;
        grid-template: 1fr / 0.5fr 1fr 1fr
    }
    .blogmore-section-body__image-description__date p{
        font-size: 12px;
    }
    .blogmore-section-body__description{
        font-size: 3.8vw;
        text-align: justify;
        width: 92%;
    }
    .blogmore-section-body__image-adapt{
        width: 94%;
    }
    .blogmore-section__body{
        display: grid;
        grid-template: repeat(4,auto) / 320px;
        justify-content: center;
        justify-items: left;
    } .blogmore-section__body p{
        margin-left: 10px;
        margin-right: 10px;
        margin-top: 1px;
        margin-bottom: 7px;
        font-size: 13px;
    }
    .blogmore-section__body h1{
        text-align: left;
        font-size: 2em;
        padding-top: 2%;
    }
}

