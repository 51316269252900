.header {
    /* position: fixed;
    z-index: 3;
    width: 100%;
    background:#119EEF;
    top: 0;
    transition: .5s ease-in-out; */
    background: #d92530;
}

.header__navbar {
    background: #d92530;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;


}

.header__navbar_boda {
    background: #fdfdfd;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    align-items: center;
    /* padding: 1rem; */


}

.header__navbar a {
    position: relative;
    font-weight: bold;
    text-align: center;
    font-family: 'Open Sans', 'Roboto', sans-serif;
    text-decoration: none;
    font-weight: bold;
    margin: 0 20px;
    color: white;
}

.navContainer {
    display: flex;
    flex-direction: row;
}

.navContainer h1 {
    color: white;
}

.navContainer2 {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
}

.navContainer2 h1 {
    color: #8c6414;
}

.cont2logo {
    display: flex;
    flex-direction: row;
    width: 80%;
    /* border: 2px solid rgb(92, 92, 92);  */
    align-items: center;
}

.cont2logo h1 {
    font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
    font-size: xx-large;
    padding-left: 3%;
}

.header__brand {
    animation: showBrand 2s forwards;
    justify-self: center;
    align-self: center;
    align-items: initial;

}

.header__brand-image {
    width: 150px;
    height: 3.5rem;
}

.header__navbar_boda-image {
    width: 200px;
    height: 2.125rem;
}




.Cabecera {
    display: flex;
    padding-top: 2%;
    padding-bottom: 2%;
    background-color: #d92530;
    justify-content: center;
    align-items: center;
}

.Cabecera label {
    color: #ffffff;
    font-size: xx-large;
    font-weight: 600;
}

.FormBusquedaEvento {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;

    /* border: 2px solid red; */
}

.Body {
    display: flex;
    flex-direction: column;
    /* border: 2px solid rgb(92, 92, 92); */
    border-radius: 20px;
    margin-top: 1%;
    width: 100%;
    align-items: center;
}

.DivEvento {
    display: flex;
    flex-direction: column;
    /* padding: 30px;  */
    width: 100%;
    align-items: center;
    border: 2px solid rgb(92, 92, 92);
}

.BuscadorEvento {
    display: flex;
    flex-direction: row;
    width: 100%;
    padding-top: 2%;
    padding-bottom: 2%;
    /* margin-bottom: 5%; */
    justify-content: center;
    align-items: center;
    background-color: #042172;
}

.inputPrincipal {
    width: 83%;
    box-shadow: border-box;
    border: 2px solid #6b6b6b;
    border-radius: 4px;
    font-size: medium;
    color: #666666;
    background-color: white;
    background-image: url('search1.png');
    background-size: 4%;
    background-position: 1% 50%;
    background-repeat: no-repeat;
    padding: 1% 3% 1% 7%;
}

.EventosContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 2%;
    background-color: #042172;
    /* border: 2px solid rgb(255, 136, 0); */
}

.EventosContainer2 {
    height: 0;
    /* border: 2px solid rgb(255, 136, 0); */
}

.DatosEvento {
    width: 77%;
    display: flex;
    flex-direction: row;
    padding-top: 1%;
    padding-bottom: 1%;
    padding-inline: 3%;
    border: 2px solid #131d38;
    border-radius: 10px;
    background-color: #ffffff;
}

.BtnContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
    /* margin-top: 3%; */
    /* border: 2px solid rgb(13, 196, 110); */
}

.ButtonBusqueda {
    width: 100%;
    background-color: #1c212f;
    border: none;
    border-radius: 4px;
    color: white;
    /* padding: 12px 0px 12px 0px; */
    /* padding-inline: 15%; */
    font-size: large;
    /* font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif; */
    font-weight: 500;
    text-decoration: none;
    cursor: pointer;
}

.EventosLista {
    display: flex;
    flex-direction: column;
    width: 85%;
    padding-bottom: 12px;
    margin-bottom: 5%;
    /* border: 2px solid turquoise; */
}

.EventosLista a {
    border: 2px solid turquoise;
}

.evento {
    background-color: lightgray;
    padding: 20px;
    margin-top: 20px;
    border-radius: 10px;
    width: 50%;
    margin-left: auto;
    margin-right: auto;
}


/*==================================================*/
.table {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.table-header,
.table-row {
    display: flex;

}

.table-cell {
    flex: 1;
    padding: 8px;
    border: 1px solid #ddd;
    text-align: center;
    min-width: 12%;
}

.table-cell-pax {
    flex: 1;
    padding: 8px;
    border: 1px solid #ddd;
    text-align: center;
    max-width: 5%;
}

.table-header {
    font-weight: bold;
    background-color: #042172;
    color: white;
}

.table-row:nth-child(even) {
    background-color: #f9f9f9;
}


.CabeceraModal {
    display: flex;
    background-color: #21a6e4;
    height: 13%;
    /* border-bottom: 2px solid white; */
    /* width: 100%; */
    color: white;
    justify-content: space-between;
    padding-left: 5%;
    padding-right: 4%;
    align-items: center;
    border-bottom: 2px solid #ffffff;
}

.CabeceraModal button {
    color: white;
    background-color: transparent;
    border: none;
    font-size: large;
    font-weight: 600;
    cursor: pointer;
}

.CabeceraModalAlerta {
    display: flex;
    background-color: #fd8421;
    height: 13%;
    /* border-bottom: 2px solid white; */
    /* width: 100%; */
    color: white;
    justify-content: space-between;
    padding-left: 5%;
    padding-right: 4%;
    align-items: center;
    border-bottom: 2px solid #ffffff;
}

.CabeceraModalAlerta button {
    color: white;
    background-color: transparent;
    border: none;
    font-size: large;
    font-weight: 600;
    cursor: pointer;
}

.CabeceraModalError {
    display: flex;
    background-color: #ff0505;
    height: 13%;
    /* border-bottom: 2px solid white; */
    /* width: 100%; */
    color: white;
    justify-content: space-between;
    padding-left: 5%;
    padding-right: 4%;
    align-items: center;
    border-bottom: 2px solid #ffffff;
}


.CabeceraModalError button {
    color: white;
    background-color: transparent;
    border: none;
    font-size: large;
    font-weight: 600;
    cursor: pointer;
}

.codigoModal {
    display: flex;
    /* background-color: #042172; */

    color: white;
    justify-content: left;
    align-items: center;
}

.codigoModal p {
    font-size: medium;
    padding-left: 1%;
}

.bodyModal {
    display: flex;
    flex-direction: column;
    background-color: white;
    height: 90%;
    justify-content: center;
    align-items: center;
}

.bodyModalForm {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: center;
    /* align-items: center; */
    /* border: 2px solid rgb(196, 240, 40); */
}

.iconoModal {
    display: flex;
    width: 11%;
    height: 74%;
    background-color: #034d8d;
    align-items: center;
    justify-content: center;
}

.bodyModalForm input {
    border: 2px solid #034d8d;
    /* background-color: #001b48; */
    padding-inline: 1.5%;
    padding-top: 10px;
    padding-bottom: 10px;
    margin-bottom: 15px;
    font-size: large;
    width: 70%;
    color: #001b48;
    /* height: 100%; */
}

.modalForminput {
    border: 2px solid #588d03;
    background-color: white;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    padding-inline: 1.5%;
    padding-top: 10px;
    padding-bottom: 10px;
    margin-bottom: 15px;
    font-size: large;
    width: 40%;
    color: #001b48;
    /* height: 100%; */
}

.bodyModalForm label {
    color: white;
    font-size: medium;
    font-weight: 600;
}

.btnModal {
    width: 60%;
    border: 2px solid #21a6e4;
    padding-top: 1.5%;
    padding-bottom: 1.5%;
    color: #21a6e4;
    font-size: large;
    font-weight: 500;
    text-decoration: none;
    cursor: pointer;
}

.btnModalAlerta {
    width: 60%;
    border: 2px solid #fd8421;
    padding-top: 1.5%;
    padding-bottom: 1.5%;
    color: #fd8421;
    font-size: large;
    font-weight: 500;
    text-decoration: none;
    cursor: pointer;
}

.btnModalError {
    width: 60%;
    border: 2px solid #ff0505;
    padding-top: 1.5%;
    padding-bottom: 1.5%;
    color: #ff0505;
    font-size: large;
    font-weight: 500;
    text-decoration: none;
    cursor: pointer;
}

.btnModalDeshabilitado {
    width: 60%;
    border: 2px solid #797778;
    padding-top: 1.5%;
    padding-bottom: 1.5%;
    color: #797778;
    font-size: large;
    font-weight: 500;
    text-decoration: none;
}

.avisoModal {
    display: flex;
    flex-direction: column;
    background-color: white;
    border-radius: 10px;
    height: 90%;
    justify-content: center;
    align-items: center;
}

.avisoModal label {
    font-size: medium;
    color: #464646;
    font-weight: 600;
}

.avisoModalIn {
    display: block;
    width: 90%;
    justify-content: center;
    align-items: center;
    color: #464646;
    margin-bottom: 3%;
}

.avisoModalIn label {
    font-size: medium;
    color: #464646;
    font-weight: 600;

}

.PieModal {
    display: flex;
    border-top: 2px solid #CECFD1;
    height: 20%;
    align-items: center;
    justify-content: space-around;
}

.btnModal2 {
    width: 30%;
    border: 2px solid #21a6e4;
    padding-top: 1.5%;
    padding-bottom: 1.5%;
    color: #21a6e4;
    font-size: large;
    font-weight: 500;
    text-decoration: none;
    cursor: pointer;
}

.btnModalCancel {
    width: 30%;
    border: 2px solid #797778;
    padding-top: 1.5%;
    padding-bottom: 1.5%;
    color: #797778;
    font-size: large;
    font-weight: 500;
    text-decoration: none;
    cursor: pointer;
}


.btnModificarLista {
    width: 100%;
    background-color: #0864ee;
    border: none;
    color: white;
    font-size: medium;
    font-weight: 600;
    cursor: pointer;
}

.btnModificarLista:hover {
    background-color: #a1a0a0;
    border-color: #33bef5;
}

.btnEliminarLista {
    width: 100%;
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
    background-color: #db1e1e;
    border: none;
    color: white;
    font-size: medium;
    font-weight: 600;
    cursor: pointer;
}

.btnEliminarLista:hover {
    background-color: #a1a0a0;
    border-color: #33bef5;
}


.divAgregar {
    display: flex;
    flex-direction: row;
    width: 85%;
    justify-content: space-between;
    align-items: center;
}

.btnAgregar {
    display: flex;
    flex-direction: row;
    width: 20%;
    background-color: #06ad4c;
    border: none;
    border-radius: 8px;
    color: white;
    padding: 5px 40px 5px 40px;
    font-size: large;
    font-weight: 600;
    text-decoration: none;
    margin: 4px 2px;
    cursor: pointer;
    align-items: center;
    justify-content: center;
}

/*===================== MODAL ============================*/
.modalRegistro {
    display: flex;
    flex-direction: column;
    width: 35%;
    height: 45%;
    margin: auto;
    padding: 0;
    margin-top: 15%;
    border-radius: 0;
    text-align: center;
    background-color: #FFFFFF;
    box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.2);
}

.modalConfirmar {
    display: flex;
    flex-direction: column;
    width: 35%;
    height: 35%;
    margin: auto;
    padding: 0;
    margin-top: 15%;
    border-radius: 0px;
    text-align: center;
    background-color: #FFFFFF;
    box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.2);
}

.modalSinData {
    display: flex;
    flex-direction: column;
    width: 35%;
    height: 35%;
    margin: auto;
    padding: 0;
    margin-top: 15%;
    border-radius: 0px;
    text-align: center;
    background-color: #FFFFFF;
    box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.2);
}

.modalCodUsado {
    display: flex;
    flex-direction: column;
    width: 35%;
    height: 35%;
    margin: auto;
    padding: 0;
    margin-top: 15%;
    border-radius: 0px;
    text-align: center;
    background-color: #FFFFFF;
    box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.2);
}

.modalAsistenciaUnico {
    display: flex;
    flex-direction: column;
    width: 40%;
    height: 40%;
    margin: auto;
    margin-top: 15%;
    padding: 0;
    text-align: center;
    background-color: #FFFFFF;
    box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.2);
}

.modalAgregar {
    display: flex;
    flex-direction: column;
    width: 40%;
    height: 40%;
    margin: auto;
    padding: 0;
    margin-top: 15%;
    border-radius: 0px;
    text-align: center;
    background-color: #FFFFFF;
    box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.2);
}

.modalEditar {
    display: flex;
    flex-direction: column;
    width: 40%;
    height: 40%;
    margin: auto;
    padding: 0;
    margin-top: 15%;
    border-radius: 0px;
    text-align: center;
    background-color: #FFFFFF;
    box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.2);
}

.modalEliminar {
    display: flex;
    flex-direction: column;
    width: 35%;
    height: 35%;
    margin: auto;
    padding: 0;
    margin-top: 15%;
    border-radius: 0px;
    text-align: center;
    background-color: #FFFFFF;
    box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.2);
}


/*-------------------- Nueva Tabla --------------------------------*/

.nuevatabla {
    display: flex;
    flex-direction: row;
    background-color: #ffffff;
    width: 100%;
    margin-bottom: 1%;
}

.nuevatabla_D1 {
    display: flex;
    flex-direction: column;
    width: 85%;
    padding-left: 1%;
    padding-right: 1%;
    padding-top: 1%;
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
    border: 2px solid #35853c;
}

.nuevatabla_D1Deshabil {
    display: flex;
    flex-direction: column;
    width: 85%;
    padding-left: 1%;
    padding-right: 1%;
    padding-top: 1%;
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
    border: 2px solid #79866a;

}

.nuevatabla_D2 {
    display: flex;
    width: 15%;
}

.buttonHabil {
    width: 100%;
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
    background-color: #35853c;
    border: none;
    color: white;
    font-size: medium;
    font-weight: 600;
    cursor: pointer;
}

.buttonHabil:hover {
    background-color: #a1a0a0;
    border-color: #33bef5;
}

.buttonDesabil {
    width: 100%;
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
    background-color: #79866a;
    border: none;
    color: white;
    font-size: medium;
    font-weight: 600;
}

.nuevaTabla_Nombre {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    font-size: medium;
    font-weight: 600;
    border-bottom: 2px solid #6b6b6b;
    width: 100%;
    color: #0f2275;
    margin-bottom: 1%;
}

.nuevaTabla_Nombre span {
    font-size: small;
    font-weight: 500;
}

.nuevaTabla_Nombre_content {
    display: flex;
    flex-direction: column;
}

.nuevatabla_content {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-bottom: 0.5%;
}

.nuevaTabla_Datos {
    display: flex;
    flex-direction: row;
    /* align-items: center; */
    padding-left: 2%;
    margin-bottom: 0.5%;
}

.nuevaTabla_Datos_in {
    display: flex;
    justify-content: left;
    align-items: center;
    /* width: 30%; */
    padding: 0;
}

.nuevatabla_label {
    font-weight: 600;
    font-size: small;
    width: 20%;
    padding-top: 0.25%;
    padding-bottom: 0.25%;
    /* border-bottom: 1px solid gray; */
}

.nuevatabla_p {
    width: 45%;
    font-size: small;
    padding-top: 0.25%;
    padding-bottom: 0.25%;
    /* border-bottom: 1px solid gray; */
}

.btnRevertir {
    background-color: transparent;
    border: none;
}

/*-------------------- ----------- --------------------------------*/






















/*========================================================*/

@media screen and (max-width:900px) {

    .modalRegistro {
        display: flex;
        flex-direction: column;
        width: 80%;
        height: 60%;
        margin: auto;
        padding: 0;
        margin-top: 35%;
        border-radius: 0;
        text-align: center;
        background-color: #FFFFFF;
        box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.2);
    }

    .modalConfirmar {
        display: flex;
        flex-direction: column;
        width: 80%;
        height: 60%;
        margin: auto;
        padding: 0;
        margin-top: 35%;
        border-radius: 0px;
        text-align: center;
        background-color: #FFFFFF;
        box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.2);
    }

    .modalSinData {
        display: flex;
        flex-direction: column;
        width: 80%;
        height: 60%;
        margin: auto;
        padding: 0;
        margin-top: 35%;
        border-radius: 0px;
        text-align: center;
        background-color: #FFFFFF;
        box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.2);
    }

    .modalCodUsado {
        display: flex;
        flex-direction: column;
        width: 80%;
        height: 60%;
        margin: auto;
        padding: 0;
        margin-top: 35%;
        border-radius: 0px;
        text-align: center;
        background-color: #FFFFFF;
        box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.2);
    }

    .modalAsistenciaUnico {
        display: flex;
        flex-direction: column;
        width: 80%;
        height: 60%;
        margin: auto;
        margin-top: 35%;
        padding: 0;
        text-align: center;
        background-color: #FFFFFF;
        box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.2);
    }

    .modalAgregar {
        display: flex;
        flex-direction: column;
        width: 80%;
        height: 70%;
        margin: auto;
        padding: 0;
        margin-top: 25%;
        border-radius: 0px;
        text-align: center;
        background-color: #FFFFFF;
        box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.2);
    }

    .modalEditar {
        display: flex;
        flex-direction: column;
        width: 80%;
        height: 70%;
        margin: auto;
        padding: 0;
        margin-top: 25%;
        border-radius: 0px;
        text-align: center;
        background-color: #FFFFFF;
        box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.2);
    }

    .modalEliminar {
        display: flex;
        flex-direction: column;
        width: 80%;
        height: 60%;
        margin: auto;
        padding: 0;
        margin-top: 35%;
        border-radius: 0px;
        text-align: center;
        background-color: #FFFFFF;
        box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.2);
    }

    /*----------------- interior Modal ------------------------*/

    .CabeceraModal {
        display: flex;
        background-color: #21a6e4;
        height: 11%;
        /* border-bottom: 2px solid white; */
        /* width: 100%; */
        color: white;
        justify-content: space-between;
        padding-left: 5%;
        padding-right: 0.5%;
        align-items: center;
        border-bottom: 2px solid #ffffff;
    }

    .CabeceraModal button {
        color: white;
        background-color: transparent;
        border: none;
        font-size: large;
        font-weight: 600;
        cursor: pointer;
    }

    .CabeceraModalAlerta {
        display: flex;
        background-color: #fd8421;
        height: 11%;
        /* border-bottom: 2px solid white; */
        /* width: 100%; */
        color: white;
        justify-content: space-between;
        padding-left: 5%;
        padding-right: 0.5%;
        align-items: center;
        border-bottom: 2px solid #ffffff;
    }

    .CabeceraModalAlerta button {
        color: white;
        background-color: transparent;
        border: none;
        font-size: large;
        font-weight: 600;
        cursor: pointer;
    }


    .CabeceraModalError {
        display: flex;
        background-color: #ff0505;
        height: 11%;
        /* border-bottom: 2px solid white; */
        /* width: 100%; */
        color: white;
        justify-content: space-between;
        padding-left: 5%;
        padding-right: 0.5%;
        align-items: center;
        border-bottom: 2px solid #ffffff;
    }

    .CabeceraModalError button {
        color: white;
        background-color: transparent;
        border: none;
        font-size: large;
        font-weight: 600;
        cursor: pointer;
    }

    .bodyModal {
        display: flex;
        flex-direction: column;
        background-color: white;
        height: 80%;
        justify-content: center;
        align-items: center;
    }

    .PieModal {
        display: flex;
        border-top: 2px solid #CECFD1;
        height: 15%;
        align-items: center;
        justify-content: space-around;
    }

    .avisoModal {
        display: flex;
        flex-direction: column;
        background-color: white;
        border-radius: 10px;
        height: 80%;
        padding-left: 2%;
        padding-right: 2%;
        justify-content: center;
        align-items: center;
    }

    .avisoModal label {
        font-size: medium;
        color: #464646;
        font-weight: 600;
    }

    .avisoModalIn {
        display: block;
        width: 90%;
        justify-content: center;
        align-items: center;
        color: #464646;
        margin-bottom: 3%;
    }

    .avisoModalIn label {
        font-size: medium;
        color: #464646;
        font-weight: 600;

    }

    .btnModal {
        width: 60%;
        border: 2px solid #21a6e4;
        padding-top: 1.5%;
        padding-bottom: 1.5%;
        color: #21a6e4;
        font-size: large;
        font-weight: 500;
        text-decoration: none;
        cursor: pointer;
    }

    .btnModalAlerta {
        width: 60%;
        border: 2px solid #fd8421;
        padding-top: 1.5%;
        padding-bottom: 1.5%;
        color: #fd8421;
        font-size: large;
        font-weight: 500;
        text-decoration: none;
        cursor: pointer;
    }

    .btnModalError {
        width: 60%;
        border: 2px solid #ff0505;
        padding-top: 1.5%;
        padding-bottom: 1.5%;
        color: #ff0505;
        font-size: large;
        font-weight: 500;
        text-decoration: none;
        cursor: pointer;
    }



    /*-------------------- Nueva Tabla --------------------------------*/

    .nuevatabla {
        display: flex;
        flex-direction: column;
        background-color: #ffffff;
        width: 100%;
        margin-bottom: 3%;
    }

    .nuevatabla_D1 {
        display: flex;
        flex-direction: column;
        width: 96.7%;
        padding-left: 1%;
        padding-right: 1%;
        padding-top: 1%;
        border-top-left-radius: 20px;
        border-top-right-radius: 20px;
        border-bottom-left-radius: 0;
        border: 2px solid #35853c;
    }

    .nuevatabla_D1Deshabil {
        display: flex;
        flex-direction: column;
        width: 96.7%;
        padding-left: 1%;
        padding-right: 1%;
        padding-top: 1%;
        border-top-left-radius: 20px;
        border-top-right-radius: 20px;
        border-bottom-left-radius: 0;
        border: 2px solid #79866a;
    }

    .nuevatabla_D2 {
        display: flex;
        width: 100%;
    }

    .buttonHabil {
        /* width: 100%; */
        border-top-right-radius: 0;
        border-bottom-right-radius: 20px;
        border-bottom-left-radius: 20px;
        background-color: #35853c;
        border: none;
        color: white;
        font-size: medium;
        font-weight: 600;
        padding-top: 2%;
        padding-bottom: 2%;
        cursor: pointer;
    }

    .buttonDesabil {
        /* width: 100%; */
        border-top-right-radius: 0;
        border-bottom-right-radius: 20px;
        border-bottom-left-radius: 20px;
        background-color: #79866a;
        border: none;
        color: white;
        font-size: medium;
        font-weight: 600;
        padding-top: 2%;
        padding-bottom: 2%;
    }



    .btnModificarLista {
        width: 100%;
        border-bottom-left-radius: 20px;
        background-color: #0864ee;
        border: none;
        color: white;
        font-size: medium;
        font-weight: 600;
        padding-top: 2%;
        padding-bottom: 2%;
        cursor: pointer;
    }

    .btnEliminarLista {
        width: 100%;
        border-top-right-radius: 0;
        border-bottom-right-radius: 20px;
        background-color: #db1e1e;
        border: none;
        color: white;
        font-size: medium;
        font-weight: 600;
        padding-top: 2%;
        padding-bottom: 2%;
        cursor: pointer;
    }

    .nuevaTabla_Nombre {
        display: flex;
        flex-direction: row;
        font-size: medium;
        font-weight: 600;
        border-bottom: 2px solid #6b6b6b;
        width: 100%;
        color: #0f2275;
        margin-bottom: 1%;
    }

    .nuevaTabla_Nombre span {
        font-size: small;
        font-weight: 500;
    }

    .nuevaTabla_Nombre_content {
        display: flex;
        flex-direction: column;
    }

    .nuevatabla_content {
        display: flex;
        flex-direction: column;
        width: 100%;
        margin-bottom: 0.5%;
    }

    .nuevaTabla_Datos {
        display: flex;
        flex-direction: column;
        /* align-items: center; */
        padding-left: 2%;
        margin-bottom: 0.5%;
    }

    .nuevaTabla_Datos_in {
        display: flex;
        justify-content: left;
        align-items: center;
        /* width: 30%; */
        padding: 0;
    }

    .nuevatabla_label {
        font-weight: 600;
        font-size: small;
        width: 20%;
        padding-top: 0.25%;
        padding-bottom: 0.25%;
        /* border-bottom: 1px solid gray; */
    }

    .nuevatabla_p {
        width: 45%;
        font-size: small;
        padding-top: 0.25%;
        padding-bottom: 0.25%;
        /* border-bottom: 1px solid gray; */
    }





}