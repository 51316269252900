/* Header block */
.header {
  position: fixed;
  z-index: 3;
  width: 100%;
  background:#119EEF;
  top: 0;
  transition: .5s ease-in-out;
}

.header--positive {
  background: white;
  box-shadow: 9px 9px 9px 0 rgba(47, 44, 44, 0.2);
}

/* navbar element */
.header__navbar {
  /* display: grid;
  grid-template: 1fr / 1fr 2fr; */
  display: flex;
  flex-flow: row wrap;
  /* display: flex; */
  /* justify-content: center; */
  justify-content: space-around;
  align-items: initial;
  padding: 1rem;

}

/* brand element */
.header__brand {
  animation: showBrand 2s forwards;
  justify-self: center;
  align-self: center;
  /* margin-right: 930px;
  margin-left: 300px; */
  align-items: initial;

}

.header__brand-image {
  width: 150px;
  height: 3.5rem;
}

/* menu element */
.header__menu {
  justify-self: center;
  align-self: center;
  transition: .5s ease-in-out;
}

.header__menu--show .menu-list {
  display: grid;
  /* display: flex; */
}
/* keyframes for Brand animation */
@keyframes showBrand {
  from {
    opacity: 0;
    transform: scale(.5);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}

@media screen and (max-width:900px){
  .header__brand-image {
    width: 160px;
    height: 20px;
  }
  .header__brand{
    margin-right: 0px;
    margin-left: 0px;
  }
}
@media screen and (max-width: 1011px) {
  .header {
    background: rgba(0, 0, 0, 0.6);
  }

  .header__navbar {
    display: grid;
    grid-template: auto 1fr / repeat(2, minmax(100px, 600px));
    /* display: flex;
    flex-direction: row; */
  }

  .burger-menu {
    display: block;
    grid-row: 1;
    grid-column: 2;
  }

  .header__menu {
    height: 0;
    display: grid;
    /* display: flex;
    flex-direction: row; */
    grid-column: 1 / span 2;
  }

  .header__menu--show {
    height: 100%;
  }
  .header__brand{
    margin-right: 0px;
    margin-left: 0px;
  }
}