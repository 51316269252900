.PagPagos{

    display: flex;
    flex-direction: column ;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    font-size:16px;
    padding-top: 7.5em;
}
.PagPagos__FormEncuesta{
    display: grid;
    grid-template: repeat(1,minmax(auto,3fr)) / repeat(1,minmax(200px,1fr));
    align-self: center;
}

.pagos h1{
  padding-top: 2%;
}
.pagos input{
    border-radius:8px 8px 8px 8px;
    font-size: 13px;
    line-height: 18px;
    background: #d1e8f5; 
    border-radius: 5px !important;
    border: 1px solid #0082ca8a; 
    box-shadow: none; 
    outline: none; 
    padding: 16px 30px 15px; 
    transition: all 0.3s ease; 
    box-sizing: border-box !important;
    width: 98%;
    display: block;
    height: 49px;
    margin:15px; 
}

.pagos label{
    font-family: 'Open Sans',Roboto;
    font-weight: bold;
  }
.body{
    margin-top: 70px;
}


@media screen and (max-width : 900px){
    .PagPagos__FormEncuesta{
      grid-template: repeat(1, minmax(auto, 1fr)) / 1fr;
      gap: 0.8rem;
      margin-left: 10px;
    }
    .pagos input{
        width: 80%;
    }
   
    
}

@media screen and (min-width: 320px) and (max-width:568px) {

  .PagPagos__FormEncuesta {
    grid-template: repeat(1, minmax(auto, 1fr)) / 1fr;
    gap: 0.8rem;
    margin-left: 10px;
  }
 
  .pagos input{
  
    width: 80%;
}

}
