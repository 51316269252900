.Empresa{
    margin: 130px 0 10px;
    display: grid;
    justify-content: center;
   
}
.Empresa h1{
    text-align: center;
}
.Empresa__Form{
    display: grid;
    grid-template: repeat(5,minmax(auto,1fr)) / repeat(1,minmax(200px,1fr));
    align-self: center;
    gap: 10px;
}
.Empresa__Form-bloques{
    display: grid;
    grid-template: repeat(2,minmax(auto,1fr)) / repeat(1,minmax(200px,1fr));
    align-self: center;
}
.Empresa__Form-bloques input{
    border-radius:8px 8px 8px 8px;
    font-size: 13px;
    background: #d1e8f5; 
    border-radius: 5px !important;
    border: 1px solid #0082ca8a; 
    height: 25px;

}
.show{
    cursor: pointer;
    border-radius:8px 8px 8px 8px;
    font-size: 13px;
    background: #eeb4b9; 
    border-radius: 5px !important;
    border: 1px solid #ca00008a; 
    height: 40px;
    padding: 2px 4px;
  }
.centrar{
    margin:0 auto;
    display:block;
    text-align:center; 
}
.button{
    color: #fff;
    display: inline-block;
    padding: 5px 5px;
    border-radius: 5px;
    background-color: #d92530;
    border: none;
    height: 40px;
    width: auto;
    margin: 10px;
    font-size:16px;
}
.body{
    margin-top: 70px;
}

 @media screen and (max-width : 900px){
    .Empresa{
        margin: 50px 0 0;
       
    }
 }
