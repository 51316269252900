.form-section__1{
  display: grid;
  grid-template: 1fr / minmax(100px , 1200px);
  justify-content: center;
}
.form__1{
  display: grid;
  grid-template: repeat(3,1fr) 2fr 1fr / 1fr;
  gap:10px;
  width: 80%;
  justify-self: center;
}
.form-section__2{
  display: grid;
  grid-template: 1fr / minmax(100px , 1200px);
  justify-content: center;
}
.form__2{
  display: grid;
  grid-template: repeat(5,1fr) / 1fr 1fr 1fr;
  gap:10px;
  width: 80%;
  justify-self: center;
}
@media screen and (max-width: 799px) {
  .form__1 {
    grid-template: repeat(2, 1fr) repeat(2, auto) / 1fr;
  }
}