@import url('https://fonts.googleapis.com/css?family=Open+Sans&display=swap');
@import url('https://fonts.googleapis.com/css?family=Roboto&display=swap');
body, textarea {
  margin: 0;
  font-family:  'Open Sans', 'Roboto',sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

html {
  /* font-size 16px */
  font-size: 100%;
}

/* title element */
.title {
  text-transform: uppercase;
  font-weight: bold;
  display: flex;
  justify-content: center;
  margin: 0;
  text-align: center;
}

/* title element with green modifier*/
/* .title--green {
  color:#69B4CA;

} */

/* title element with red modifier*/
.title--red {
  color: #FF4544;
 
}

/* title element with gray modifier*/
.title--gray {
  color: #707070;
}

/* x small title */
.title--x-small {
  font-size: 1.5rem; /* 24px */
}

/* small title */
.title--small {
  font-size: 2.5rem; /* 40px */
}

/* medium title */
.title--medium {
  font-size: 3.75rem; /* 60px */
}

/*x medium title */
.title--x-medium{
 font-size:2.1875rem;/*35px*/
}
/* large title */
.title--large {
  font-size: 5rem; /* 80px */
  padding-top: 4%;
}
/* large title mobile */
.title--large__mobile {
  font-size: 3rem; /* 80px */
  padding-top: 4%;
}

/* button element */
.button {
  border: 1px solid transparent;
  padding: .375rem .75rem;
  border-radius: 0.25rem;
  font-size: 1rem;
  line-height: 1.5;
  background-color: transparent;
  color: #212529;
}

.button--blue {
  color: #ffffff;
  background-color: #69B4CA;
  border-color: #69B4CA;
}
.button--darkblue {
  color: #ffffff;
  background-color: #d92530;
  border-color: #d92530;
  border-radius: 5px;
}
@media (min-width: 768px) and (max-width: 1200px) {
  html {
    font-size: 80%; /* 12.8px */
  }
  .button {
    font-size: 1.7rem;
  }
}

@media screen and (max-width: 767px) {
  html {
    font-size: 55%; /* 12px */
  }
  .button {
    font-size: 1.7rem;
  }
}

@media screen and (max-width: 400px) {
  html {
    font-size: 50%; /* 8px */
  }
  .button {
    font-size: 1.7rem;
  }
}