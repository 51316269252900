@import url('https://fonts.googleapis.com/css?family=Open+Sans&display=swap');
@import url('https://fonts.googleapis.com/css?family=Roboto&display=swap');
.cardInformation{
    /* display: grid;
    grid-template: repeat(1, minmax(200px, 1fr))/ 80px 200px; */
    display: flex;
    background: #FFFFFF;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.20);
    overflow: hidden;
    position: relative;
    justify-items: center;
    margin:10px;
    height: auto;
    width: 300px;
    
}
.imagen{
    width: 100%;
}
.card-information__line{
    margin-right:162px;;
    background-color: #0082ca;
    height: 6px;
}
.cardInformation_ico{
    align-self: center;
    margin: 10px;
}
.nosotros__text{
    font-family:  'Open Sans', 'Roboto',sans-serif;
    font-size: 1.1rem;
    margin-right: 12px;
}
@media screen and (max-width : 900px){
    .cardInformation{
        /* grid-template: repeat(1, minmax(200px, 1fr)) / 50px auto; */
        width: 290px;
    }
    .nosotros__text{

        font-size: 1.8rem;
    }
    .subtitle__text{
        font-size: 2rem;
    }
    .card-information__line{
        margin-right:220px;
        height: 3px;
       
    }
    
  }
