@import url('https://fonts.googleapis.com/css?family=Open+Sans&display=swap');
@import url('https://fonts.googleapis.com/css?family=Roboto&display=swap');
.card-solutions{
    padding: 0.63rem;
    background: #f7f7f7;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.30);
    border-radius: 4px 4px 4px 4px;
    overflow: hidden;
    position: relative;
    height: auto;
    width: 350px;
    margin: 15px;
    /* align-content: center;
    align-items: center; */
}
.card-solutions__text-hover{
    position:absolute;
    height: 100%;
    background-color: white;
    top:0;
    left:0;
    opacity: 0;
    padding:10px;
    margin:0;
  }
.card-solutions:hover .card-solutions__text-hover{
    opacity: 1;
    -webkit-transition: opacity 500ms;
    -moz-transition: opacity 500ms;
    -o-transition: opacity 500ms;
    -ms-transition: opacity 500ms;
    transition: opacity 500ms;
  }

  .card-solutions__line {
    margin-right: 285px;
    border-radius: 10px;
    background-color: #d92530;
    height: 7px;
}
.card-solutions__sectionPlan{
    display: flex;
    font-size: 1.5rem;
    font-weight: bold;
}
.card-solutions__image {
    margin-right: 1rem;
    width: 100%;
    height: 300px;   
}
.card-solutions__seccionPrecio{
   
    align-content: center;
    align-self: center;
}
.card-solutions__seccionPrecio p{
    font-size: 3rem;
    font-weight: bold;
    text-align: center;
}
 .card-solutions__text{
    font-family:'Open Sans',Roboto;
    font-size: 1.8rem;
    color: #808495;
    font-weight: bold;
    text-align: initial;
    margin-block-start: 30px;
    padding: 15px;
 
 }
.card-solutions__seccionInfo p{

    font-family: 'Open Sans',Roboto;
    color: #FF4544;
    font-weight: bold;
    text-align: center;
}
.card-solutions__seccionInfo span{

    font-family: 'Open Sans',Roboto;
    font-size: 1.7rem;
    color: #808495;
    font-weight: bold;
    text-align: center;
}
.card-solutions__seccionFooter{
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    font-style: italic;
    margin: 20px;
}
.sizeButton{
    font-family:  'Open Sans', 'Roboto',sans-serif;
      font-size: 1rem;
      color:white;
      height: 45px;
      font-weight: bold;
      text-align: center;
      background: #d92530;
       border: #d92530 1px solid; 
      border-radius: 5px;
      padding: 0 15px;
}
.card-solutions__seccionInformacion ul li{
    font-size: 1.4rem;
    font-family:  'Open Sans', 'Roboto',sans-serif;

}
.card-oferta{
    font-weight: bold;
}

@media screen and (max-width:900px){
    .card-solutions{
        width: 270px;
    }
    .card-solutions__text{ 
        font-size: 2.5rem;
        text-align: initial;
     }
     .card-solutions__seccionInfo p{
        font-size: 2rem;
     }
     .card-solutions__seccionInfo span{
        font-size: 2rem;
     }
     .card-solutions__sectionPlan{
  
        font-size: 1.8rem;
     
    }
     .card-solutions__seccionInformacion ul li{
        font-size: 1.7rem;
     }
      .sizeButton{
        font-size: 1.2rem;} 
    .card-solutions__line {
        margin-right: 245px;
        border-radius: 10px;
        background-color: #d92530;
        height: 4px;
    }
}


@media (min-width: 768px) and (max-width: 1024px) {
    .card-solutions {
        width: 320px;
    }
  
}
  
