@import url('https://fonts.googleapis.com/css?family=Open+Sans&display=swap');
@import url('https://fonts.googleapis.com/css?family=Roboto&display=swap');
.menu-list {
    list-style: none;
    /* display: grid;
    grid-template: 1fr / repeat(5, minmax( auto, 200px)); */
    display: flex;
    flex-direction: row wrap;
    padding: 0;
    justify-items: center;
}

.menu-list li {
    align-self: center;
    border-radius: 5px;
}
 
.menu-list li a{
    position: relative;
    font-weight: bold;
    text-align: center;
    font-family:  'Open Sans', 'Roboto',sans-serif;
    text-decoration: none;
    font-weight: bold;
    margin: 0 20px;
}

.header__menu .menu-list li a {
  color: white;
}

.header__menu--positive .menu-list li a{
    color: black;
}

.menu-list li a::after{
  position: absolute;
  bottom: 7px;
  left: 0px;
  width: 100%;
  height: 3px;
  content: "";
  opacity: 0;
  transition: opacity 0.3s ease 0s, transform 0.3s ease 0s;
  transform: translateY(20px);
}

.header__menu .menu-list li a::after {
  background-color: white;
}

.header__menu--positive .menu-list li a::after {
  background-color: black;
}

.menu-list li a:hover::after{
  opacity: 1;
  transform: translateY(15px);
}

.menu-list li a:hover{
  font-size: 1.1rem;
}

.menu-list__submenu{
  list-style: none;
  position: absolute;
  display: none;
  width: 13%;
  background-color: #0082ca;
  opacity: 0.8;
  margin-left: -0%px;
}

.menu-list__submenu p a{
  display: grid;
  grid-template: auto/auto;
  /* display: flex; */
  padding: 15px;
}

.menu-list li:hover .menu-list__submenu{
  display: grid;
  /* display: flex; */
}

.menu-list__logo{
  display: flex;
  font-weight: bold;
}


@media screen and (max-width: 1066px) {
  .menu-list {
    grid-template: 1fr / repeat(5, minmax( auto, 200px));
    /* display: flex; */
  }

  .menu-list__logo{
    font-weight: bold;
  }

  .menu-list li:hover .menu-list__submenu{
    display: inline-table;
    margin-left: 2%;
  }

  .menu-list li:first-child {
    /* display: none; */
  }
  .menu-list__submenu{
    list-style: none;
    position: absolute;
    display: none;
    width: 13%;
    background-color: #0082ca;
    opacity: 0.8;
  }
  
}

@media screen and (max-width: 1011px) {
  .header--positive .menu-list li a{
    color: white;
  }

  .menu-list {
    grid-template: repeat(5, minmax(50px, 1fr)) / 1fr; 
    display: none;
    /* display: flex; */
    justify-items: normal;
  }

  .menu-list li a{
    font-size: 2rem;
    color: white;
  }

  .header__menu--positive .menu-list li a::after {
    background-color: white;
  }
  
  .menu-list li a:hover{
    font-size: 2.5rem;
  }
  .menu-list__logo{
    font-weight: bold;
    font-size: 2rem;
  }
}
@media (min-width: 700px) and (max-width: 770px){
  .menu-list__submenu{
    list-style: none;
    position: absolute;
    display: none;
    width: 39%;
    background-color: #0082ca;
    opacity: 0.8;
    margin-left: -0%px;
  }

  .menu-list li:hover .menu-list__submenu{
    display: inline-table;
    margin-left: 2%;
    font-size: 0.1rem;
  }
}
@media (min-width: 1023px) and (max-width: 1024px){
  .menu-list li:hover .menu-list__submenu{
    display: inline-table;
    margin-left: -9%;
    margin-top: 3%;
  }
}
@media (min-width: 319px) and (max-width: 320px){
  .menu-list li:hover .menu-list__submenu{
    display: inline-table;
    margin-left: 1%;
    font-size: 0.1rem;
  }
}