.Card_Solutions {
    padding: 4em 1rem;
    display: flex;
    flex-direction: column;
    margin: 0;
  }
  .Card_Solutions__info {
    /* display: grid;
    grid-template: repeat(1, minmax(200px, 1fr)) / repeat(2, minmax(150px, 500px));
    gap: 8.75rem; */
    width: 1160px;
    margin: 0 auto;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-around;
  }
  .card-solution-grid{
    /* margin-top: 20px;
    display: grid;
    justify-content: center; */
    display: flex;
    justify-items: center;
    margin: 0 auto;
  }
  .Card_Solutions_title{
    /* display: grid;
    grid-template: 1fr / 1fr; */
    display: flex;
    flex-direction: column;
    font-weight: bold;
    justify-content: center;
  }
  
  .Card_Solutions_p{
    justify-self: center;
    font-size: 2.5rem;
    font-weight: bold;
    padding-top: 2%;
  }
  .solutionsSecond {
    background:#69B4CA;
    justify-content: center;
    /* display: grid;
    grid-template: 1fr / minmax(100px,1200px); */
    display: flex;
    color: white;
  }
  
  .Card_Solutions__paragraph {
    padding: 1.5em 3rem; /* mezclando em y rem */
    margin: 0;
    font-size:3rem;
    text-align: center;
    font-family:  'Open Sans', 'Roboto',sans-serif;    font-weight: bold;
  }
  
  @media screen and (min-width : 760px) and (max-width: 780px){
    .Card_Solutions__info {
      justify-items: center;
        /* grid-template: repeat(1, minmax(150px, 550px) )/ repeat(1, minmax(150px, 250px)); */
     display: flex;
     flex-direction: column;
    }
  }
  @media screen and  (max-width : 750px){
    .Card_Solutions__info {
      justify-items: center;
      flex-direction: column;
      display: flex;
      width: 300px;
      /* grid-template: repeat(1, minmax(150px, 350px) )/ repeat(1, minmax(150px, 250px)); */
     
    }
  }