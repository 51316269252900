/* Hero block */
.hero {
  height: 66vh;
  overflow: hidden;
  position: relative;
  margin-top: 5.5rem;
  background-image:url("./Hero.png");
  background-size:100% 100%;
  background-repeat: no-repeat;
}
/* hero intro text element */
.intro__text {
  position: relative;
  z-index: 2;
  /* display: grid;
  grid-template: 100vh / minmax(100px, auto); */
  display: flex;
  flex-direction: row;
  justify-content: center; 
  align-self: initial;
}
.intro-text__container button{
  height: 50px;
  width: 287px;
  color: rgb(20, 94, 255);
  background:#ffffff ;
  border:#ffffff ;
  font-size: 22px;
  font-family: 'Open Sans', Roboto;
  border-radius: 5px;
}
.intro-text__container a{
 margin: 15px;
}
.intro-text__paragraph {
  color: white;
  margin-left: 15px;
  font-size: 4rem;
  font-family:'Open Sans', Roboto;
}

@media screen and (max-width:900px){
  .hero {
    background-image: url("./Heromovil.png");
    background-size: 100% 100%;
    height: 35vh;
    overflow: hidden;
    position: relative;
    margin-top: 0;
    padding-top: 4.5rem;
  }
  .intro-text__paragraph {
    color: white;
    margin-left: 15px;
    font-size: 2.5rem;
    font-family:'Open Sans', Roboto;
  }
  .intro-text__container button{
    height: 25px;
    width: 100px;
    color: white;
    background: #242348;
    border: #242348;
    font-size: 9px;
    font-family: 'Open Sans', Roboto;
  }
}
