@import url('https://fonts.googleapis.com/css?family=Open+Sans&display=swap');
@import url('https://fonts.googleapis.com/css?family=Roboto&display=swap');
.solutions {
  /* display: grid;
  grid-template-rows: auto 1fr; */
  display: flex;
  flex-direction: column;
  padding: 4em 1rem;
  margin: 0;

}
.solutions__info {
  /* display: grid; 
  grid-template: repeat(1, minmax(200px, 1fr)) / repeat(3, minmax(200px, 380px)); 
  gap: 1.75rem;
  justify-content: center; */
  list-style-type: none;
  margin: 20px auto;
  padding: 0;
   /*Flexbox setup*/
   display: flex;
   flex-flow: row wrap;
   justify-content: center;
  
}
.solutions__title{
  padding-bottom: 30px;
  padding-top: 30px;
  
}

.solutions__p{
  text-align: center;
  font-size: 1.5rem;
  font-weight: bold;
}
.solutionsSecond {
  background:#69B4CA;
  justify-content: center;
  display: flex;
  /* display: grid;
  grid-template: 1fr / minmax(100px,1200px); */
  color: white;
}

.solutionsSecond__paragraph {
  padding: 1.5em 3rem; /* mezclando em y rem */
  margin: 0;
  font-size:3rem;
  text-align: center;
  font-family:  'Open Sans', 'Roboto',sans-serif;  font-weight: bold;
}

@media screen and (max-width : 900px){
  .solutions__info {
    /* display: flex;
    flex-direction: column; */
    display: grid;
    grid-template: repeat(1, minmax(200px, 1fr)) / 1fr;
  }
  .solutions__p{
    font-size: 1.7rem;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .solutions__info {
    display: grid;
    grid-template: repeat(1, minmax(200px, 1fr)) / repeat(2,minmax(200px,1fr));
  
  }

}

