.FormReclamaciones{
    padding-top: 80px;
    min-width: 90%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0 auto;

}
.DivGral{
    margin-top: 50px; 
    display: flex;
    justify-content: center;
    align-items: center;
}
.SectionReclamaciones{
    padding-top: 80px;
    width: 75%;

    display:block;
    justify-content: center;
    /*flex-direction: row;*/

    /* display: flex;
    justify-content: center;
    align-items: center; */

    /* border: 2px solid lime; */
}

.Titleform{
    display: flex;
    justify-content: center;
    border: 2px solid fuchsia;
}

 /* ========== Busqueda Entrada ============================== */
.FormBusquedaEmpresa{
    display: flex;
    align-items: center;
    justify-content:space-around;
    flex-direction: row;
    /* border: 2px solid red; */
}

.BusquedaLibroReclamaciones{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /* border: 2px solid yellow; */
}

.PruebaDivBusqueda{ 
    border-left: 2px solid #ccc;
    display: flex;
    flex-direction: column;
    width: 80%;
    padding-top: 2%;
    margin-bottom: 4%;
    align-items: center;
}

.RuCReclamaciones{
    display: flex;
    flex-direction: row;
    width: 83%;
    padding-bottom: 12px;
    margin-bottom: 5%;
    /* border: 2px solid turquoise; */
}

.DatosRucContainer{
    width: 83%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 7%;
    /* border: 2px solid rgb(255, 136, 0); */
}

.DatosRucContainer2{
    width: 83%;
    display: flex;
    flex-direction: column;
    align-items: center;
    /* border: 2px solid rgb(255, 136, 0); */
    margin-bottom: 20%;
}

.DatosRuc{
    width: 90%;
    display: flex;
    flex-direction: row;
    padding-top: 1%;
    padding-bottom: 1%;
    padding-inline: 3%;
    border: 2px solid #131d38;
    border-radius: 10px;
}

.DatosRecuperados{
    display: flex;
    flex-direction: column;
    padding-left: 3%;
}

.DatosRecuperados label{
    font-size: 15px;
    font-weight: 500;
    /* font-family:'Cambria'; */

}

.DatosRecuperadosTitle{
    display:inline-flex;
    flex-direction: column;
    
}

.DatosRecuperadosTitle label{
    font-size: 15px;
    font-weight: 600;
    /* font-family:'Roboto'; */
}

.BtnBusqueda{
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-top: 7%;
    /* border: 2px solid rgb(13, 196, 110); */
}
.inputPrincipal{
    width: 100%;
    box-sizing: border-box;
    border: 2px solid #6b6b6b;
    border-radius: 4px;
    font-size: large;
    color:#666666 ;
    background-color: white;
    background-image: url('search1.png');
    background-size: 4%;
    background-position: 1% 50%; 
    background-repeat: no-repeat;
    padding: 2% 3% 2% 7%;
 }

.ButtonBusqueda{
    width: 100%;
    background-color: #1c212f;
    border: none;
    border-radius: 4px;
    color: white;
    padding: 12px 0px 12px 0px;
    padding-inline: 15%;
    font-size: large;
    /* font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif; */
    font-weight: 500   ;
    text-decoration: none;
    cursor: pointer; 
}

.titleRuc{
    font-size: 18px;
    font-weight: 700;
    margin-bottom: 5%;
}

/* ========================================================== */

.FormNuevoReclamaciones{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    border-top: 5px solid #1c212f;
}

.LogoLibroReclamaciones{
    display: flex;
    width: 100%;
    flex-direction: row;
    /* justify-content: space-around; */
    align-items: center;
    padding-left: 2%;
    padding-top: 0.5%;
    padding-bottom: 0.5%;
    background-color: #1c212f;
}
.libroNombre{
    display: flex;
    width: 80%;
}

.PruebaDivR{ 
    /* border: 2px solid rgb(249, 69, 255); */
    display: flex;
    flex-direction: column;
    width: 75%;
    padding-top: 2%;
    align-items: center;
}

.PruebaDivR2
{ 
    /* border: 2px solid rgb(249, 69, 255); */
    display: flex;
    flex-direction: column;
    width: 75%;
    padding-top: 2%;
    margin-bottom: 10%;
    align-items: center;
}

.tit1{
    display: flex;
    justify-content: center;
    align-items: center;
}

.tit1 label{
    font-size:xx-large;
    font-family: Tahoma;
    font-weight: 500;
    color:rgb(51, 51, 51);
    margin-bottom: 15px;
}

.tit2{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 4%;
}

.DatosPersonales{
    display: flex;
    flex-direction: column;
    border: 2px solid rgb(92, 92, 92);
    border-radius: 20px;
    padding: 30px; 
    width: 85%;
    align-items: center;
}

.cajas{
    width: 95%;
    /* border: 1px solid rgb(170, 28, 28); */
}
.cajas label{
    font-family: Tahoma;
    font-size: medium;
    font-weight: 500;
    color:rgb(4, 32, 124);
}

.cajas input{
    border: 1px solid rgb(92, 92, 92);
    border-radius: 20px;
    padding-inline: 1.5%; 
    padding-top: 10px;
    padding-bottom: 10px;
    margin-bottom: 15px;
    font-size: medium;
    width: 96%;

}

.cajas input[type=checkbox]{
    border: 1px solid rgb(92, 92, 92);
    border-radius: 20px;
    padding-inline: 3%; 
    padding-top: 10px;
    padding-bottom: 10px;
    margin-bottom: 15px;
    font-size: medium;
    width:2%;
    align-self: flex-start;

}

.cajas input[type=radio]{
    border: 1px solid rgb(92, 92, 92);
    border-radius: 20px;
    padding-inline: 1.5%; 
    padding-top: 10px;
    padding-bottom: 10px;
    margin-bottom: 15px;
    font-size: medium;
    width:2%;
    align-self: flex-start;

}

.cajas textarea{
    border: 1px solid rgb(92, 92, 92);
    border-radius: 20px;
    padding-inline: 1.5%; 
    padding-top: 10px;
    padding-bottom: 10px;
    margin-bottom: 15px;
    font-size: medium;
    width: 96%;

}

.cajas1{
    width: 95%;
    flex-direction: row;
    /* border: 1px solid rgb(170, 28, 28); */
}
.cajas1 label{
    font-family: Tahoma;
    font-size: medium;
    font-weight: 500;
    color:rgb(4, 32, 124);
}

.cajas1 input{
    border: 1px solid rgb(92, 92, 92);
    /*border-radius: 20px;*/
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
    padding-inline: 1.5%; 
    padding-top: 10px;
    padding-bottom: 10px;
    margin-bottom: 15px;
    font-size: medium;
    width: 80%;
}

.enableButton{
    border: 1px solid #3181f8;
    background-color: #3181f8;
    /*border-radius: 20px;*/
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
    padding-inline: 1.5%; 
    padding-top: 10px;
    padding-bottom: 10px;
    margin-bottom: 15px;
    font-size: medium;
    color: #ffffff;
    font-weight: 700;
    width: 16%;
    cursor: pointer; 
}

.disableButton{
    border: 1px solid rgb(92, 92, 92);
    background-color: #555555;
    /*border-radius: 20px;*/
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
    padding-inline: 1.5%; 
    padding-top: 10px;
    padding-bottom: 10px;
    margin-bottom: 15px;
    font-size: medium;
    color: #ffffff;
    font-weight: 700;
    width: 16%;
    cursor: pointer; 
}


.tituloreclamo{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    border-bottom: 1px solid rgb(92, 92, 92);
    margin-bottom: 20px;
    width: 95%;
}

.tituloreclamo label{
    font-size:x-large;
    font-family: Tahoma;
    font-weight: 500;
    color:rgb(51, 51, 51);
}

.subtituloreclamo{
    font-size:medium;
    font-family: Tahoma;
    font-weight: 500;
    color:rgb(51, 51, 51);
}

.texto{
    font-size:small;
    font-family: Tahoma;
    font-weight: 500;
    color:rgb(51, 51, 51);
    text-align: justify;
}
.textored{
    font-size:small;
    font-family: Tahoma;
    font-weight: 600;
    color:rgb(175, 7, 7);
    margin-bottom: -5px;
    text-align: left;
}

.documento{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 95%;
    /* border: 1px solid rgb(224, 171, 24); */
}

.documento label{
    font-family: Tahoma;
    font-size: medium;
    font-weight: 500;
    color:rgb(4, 32, 124);
}

.Tdocumento{
    display: flex;
    flex-direction: column;
    width: 40%;

}
.Tdocumento select{
    border: 1px solid rgb(92, 92, 92);
    border-radius: 20px;
    padding-inline: 3%; 
    padding-top: 10px;
    padding-bottom: 10px;
    margin-bottom: 15px;
    font-size: medium;
    width: 90%;

}

.Ndocumento{
    display: flex;
    flex-direction: column;    
    width: 60%;
    /* border: 1px solid rgb(59, 158, 13); */
}
.Ndocumento input{
    border: 1px solid rgb(92, 92, 92);
    border-radius: 20px;
    padding-inline: 1.5%; 
    padding-top: 10px;
    padding-bottom: 10px;
    margin-bottom: 15px;
    font-size: medium;
    width: 90%;
}

.checkeaado{
    border: 1px solid rgb(238, 6, 6);
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}

.PaginaCentral label{
    font-family: Tahoma;
    font-size: large;
    font-weight: 500;
    color: #1c212f ;
    
}

.imgReclamaciones{
    height: 280;
    width: 103;
}
.TitReclam{
    display: flex;
    justify-content: center;
    align-content: center;
    margin-bottom: 20%;

}

.tituloReclamaciones{
    text-align: center;
    font-size:x-large;
    font-weight: 600;
    color: #1c212f;
}

.tituloReclamacionesEmp{
    text-align: center;
    font-size: large;
    font-weight: 600;
    color: white;
    padding-left: 2%;
}

.incidencia{
    display: flex;
    justify-content:right;
    padding-inline: 1%;
    width: 95%;
    /* border: 2px solid turquoise; */
}

.incidencia label{
    text-align: right;
}

.Formbutton{
    width: 40%;
    background-color: #1c212f;
    border: none;
    border-radius: 8px;
    color: white;
    padding: 8px 60px 8px 60px;
    font-size: 16px;
    text-decoration: none;
    margin: 4px 2px;
    cursor: pointer; 
}

.PaginaCentral{
    justify-content:space-between;
    justify-self: center;
    align-items: center;
    /*width: 97%;*/
    padding-inline: 1.5%;
    display: flex;
    flex-direction: row;
     
}

.PaginaPrincipal{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-self: center;
    justify-content: center;
    justify-items: center;
}

.ReclamoQueja{
    display: flex;
    flex-direction: row;
}

.ContinuarReclamaciones{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: 2px solid red;
}
.TraerEmpresa{
    border: 2px solid turquoise;
    flex-direction: row;
}

.DatosModal {
    display: flex;
    flex-direction: column;
    justify-content:center;
}

.DatosModal {
    display: flex;
    flex-direction: column;
    justify-content:center;
    align-items: center;
    border: 1px solid rgb(92, 92, 92);
}

.DatosModal input{
    border: 1px solid rgb(92, 92, 92);
    border-radius: 20px;
    padding-inline: 1.5%; 
    padding-top: 5px;
    padding-bottom: 0px;
    margin-bottom: 15px;
    font-size: 28px;
    width: 90%;
    text-align: center;
}

.DatosModal button{
width: 90%;
background-color: #1c212f;
border: none;
border-radius: 8px;
color: white;
padding: 8px 60px 8px 60px;
font-size: 16px;
text-decoration: none;
margin: 4px 2px;
cursor: pointer; 
}





/*================================================================================*/
.errorMsg{
    color: red;
    
}
.Form_encabezado{
    background:	#E6E6E6;
    width: 40%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0 auto;

}
.Form_encabeza{
    background:	#E6E6E6;
    width: 40%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    float: right;

}
.Form_colum{
    display: flex;
    flex-direction: row wrap;
    justify-content: space-between;
}

.Form_colum select{
  border-radius:8px 8px 8px 8px;
  font-size: 13px;
  line-height: 18px;
  border-radius: 5px !important;
  border: 1px solid #0082ca8a; 
  box-shadow: none; 
  outline: none; 
  padding: 16px 30px 15px; 
  transition: all 0.3s ease; 
  box-sizing: border-box !important;
  display: block;
  height: 49px;
  margin:15px;
}
.Form_filas{
    display: flex;
    flex-direction: row wrap;
    /* justify-content: flex-start; */
}
.Form_filas input[type="radio" i]{
    background-color: initial;
    cursor: default;
    -webkit-appearance: radio;
    box-sizing: border-box;
    padding: initial;
    border: initial;
    height: 20px;
    margin: 10px;
}

.FormReclamaciones p{
    color: #4B75B8;
    font-weight: bold;
    font-size: 18px;
}
.FormReclamaciones label{
    font-weight: bold;
    font-size: 14px;
    margin: 20px;
 
}
.FormReclamaciones input{
    border-radius:8px 8px 8px 8px;
    font-size: 13px;
    line-height: 18px;
    background:#CFE6EC;
    border-radius: 5px !important;
    border: 1px solid #0082ca8a; 
    box-shadow: none; 
    outline: none; 
    padding: 16px 30px 15px; 
    transition: all 0.3s ease; 
    box-sizing: border-box !important;
    width: 80%;
    display: block;
    height: 40px;
    margin:15px;
}
.FormReclamaciones-input__textArea{
    border-radius:8px 8px 8px 8px;
    font-size: 13px;
    line-height: 18px;
    background: #ffffff;;
    border-radius: 5px !important;
    border: 1px solid #0082ca8a; 
    box-shadow: none; 
    outline: none; 
    padding: 16px 30px 15px; 
    transition: all 0.3s ease; 
    box-sizing: border-box !important;
    width: 80%;
    display: block;
    height:60px;
    margin:15px;
}
.FormReclamaciones textarea{
    border-radius:8px 8px 8px 8px;
    font-size: 13px;
    line-height: 18px;
    background:#CFE6EC;
    border-radius: 5px !important;
    border: 1px solid #0082ca8a; 
    box-shadow: none; 
    outline: none; 
    padding: 16px 30px 15px; 
    transition: all 0.3s ease; 
    box-sizing: border-box !important;
    width: 80%;
    display: block;
    height:auto;
    margin:15px;
}
.FormReclamaciones button{
    background: #0082CA;
    text-decoration: none;
    text-transform: uppercase;
    border: none;
    color: white;
    -webkit-transition-duration: 0.4s;
    transition-duration: 0.4s;
    font-weight: bold;
    border-radius: 5px;
    padding: 10px;
    margin:10px;
}



