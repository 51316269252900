.form-field__1 {
  box-sizing: border-box;
  width: 100%;
  /* padding: 0.8rem; */
  justify-self: center;
}
.form-field__2 {
  box-sizing: border-box;
  border-radius: 10px;
  width: 100%;
  /* padding: 0.8rem; */
  justify-self: center;
}
.form-field__2:nth-of-type(1){
  grid-column: 1 / 3;
}
.form-field__2:nth-of-type(2){
  grid-column: 1 / 2;
}
.form-field__2:nth-of-type(3){
  grid-column: 1 / 2;
}
.form-field__2:nth-of-type(4){
  grid-column: 2 / 3;
}
.form-field__2:nth-of-type(5){
  grid-column: 1 / 2;
  grid-row: 3 / 4;
}
.form-field__2:nth-of-type(8){
  grid-column: 3 / 4;
  grid-row: 5/6;
}
.form-field__2:nth-of-type(7){
  grid-column: 2 / 3;
  grid-row: 5/6;
} 
.form-field__2:nth-of-type(6){
  grid-column: 1 / 2;
  grid-row: 5/6;
}

.form-control {
  box-sizing: border-box;
  border: 1px solid #ced4da;
  border-radius: .25rem;
  padding: .375rem .75rem;
  width: 100%;
  font-size: 1.4rem;
  line-height: 18px;
  background: #d1e8f5;
  border-radius: 5px !important;
  border: 1px solid #0082ca8a;
  box-shadow: none;
}

.form-control:focus {
  color: #495057;
  background-color: #fff;
  border-color: #80bdff;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.form-control::placeholder {
  color: #6c757d;
  opacity: 1;
}

textarea.form-control {
  resize: vertical;
}

@media screen and (min-width: 300px) {
  .form-field:nth-child(3), .form-field:last-child  {
    /* grid-column: 1 / span 2; */
  }
  .form-control {
    font-size: 1.7rem;
  }
}