.RespuestaForm{
    display: grid;
}
.RespuestaForm h1{
    color: red;
}
.RespuestaForm p{
      
    font-size:18px;
    font-weight: bold;
    color: #d92530;
 
 }
 
@media screen and (max-width : 900px){
    
    .RespuestaForm  p{
       
       font-size:14px;
       font-weight: bold;
   
    }
 }