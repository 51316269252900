/* Burger menu block */
.burger-menu {
    display: none;
    justify-self: end;
    align-self: center;
    width: 3.5rem;
    height: 2.5rem;
    position: relative;
    transition: .5s ease-in-out;
}

.burger-menu span{
    display: block;
    position: absolute;
    width: 100%;
    left: 0;
    height: .3125rem;
    background: white;
    padding: 0;
    border-radius: 5px;
    transition: .25s ease-in-out;
}

.burger-menu span:nth-child(1) {
    top: 0rem;
}

.burger-menu span:nth-child(2) {
    top: 1.25rem;
}

.burger-menu span:nth-child(3) {
    top: 1.25rem;
}

.burger-menu span:nth-child(4) {
    top: 2.5rem;
}
.burger-menu span:nth-child(5) {
    top: 2.5rem;
}

.burger-menu--open span:nth-child(1) {
    top: 1.25rem;
    width: 0%;
    left: 50%;
}

/*  */
.burger-menu--open span:nth-child(2) {
    transform: rotate(45deg);
}

.burger-menu--open span:nth-child(3) {
    transform: rotate(-45deg);
}

.burger-menu--open span:nth-child(4) {
    top: 1.25rem;
    width: 0%;
    left: 50%;
}
.burger-menu--open span:nth-child(5) {
    top: 1.25rem;
    width: 0%;
    left: 50%;
}

@media screen and (max-width: 550px){

    
}