.FormEncuesta{
    display: grid;
    justify-content: center;
    margin: 10px;
    
    }
    .FormEncuesta h1{
        /* color: #d92530; */
        font-size: 25px;
        text-align: center;
    }
    .FormEncuesta button{
        width: 135px;
        height: 36px;
        background: #d92530;
        border: #d92530;
        color: #ffffff;
        font-size: 16px;
        float: right;
        margin: 10px ;
    }
    .FormEncuesta__right{
     float: right;
    
    }
    .FormEncuesta__bloque1{
        display: grid; 
        grid-template: repeat(1,auto)/ 250px auto; 
        gap:3rem;
       
    }
    .FormEncuesta__bloque1-image{
        width:183px;
        height:183px;
        border-radius:160px;
        border:4px solid #d92530;
        display:block;
        margin:auto;
        
      
    }
    .FormEncuesta__image{
        display:block;
        margin:auto;
        
      
    }
    .FormEncuesta__bloque2{
    display: grid;
    }
    .FormEncuesta__bloque2-columnas{
    display: grid;
    grid-template: repeat(1,minmax(auto,1fr)) / repeat(2,minmax(50px,180px));
    
    }
    
    .FormEncuesta__bloque3{
    display: grid;
    
    }
    .FormEncuesta__incidencia{
        font-weight: bold;
    }
    .FormEncuesta__p{
        color:#d92530 ;
    }
    .FormEncuesta__bloque3-columnas{
    display: grid;
    grid-template: repeat(1,minmax(auto,1fr)) / repeat(2,minmax(200px,1fr));
    
    }
    
    .FormEncuesta__bloque3-1columnas{
    display: grid;
    grid-template: repeat(1,minmax(auto,1fr)) / repeat(1,minmax(200px,1fr));
    
    }
    
    @media screen and (max-width : 900px){
        .FormEncuesta__image{
            width: 180px;  
           height: auto;
        }
        .FormEncuesta__bloque1-image{
            width:154px;
            height:155px;
            float: none;
            
        }
        .FormEncuesta__bloque1 {
          grid-template: repeat(1, minmax(40px, 1fr)) / 1fr;
        }
        .FormEncuesta__bloque2-columnas{
            display: grid;
            grid-template: repeat(1, minmax(40px, 1fr)) / 1fr;
            
            
        }
        .FormEncuesta__bloque3-columnas{
            display: grid;
            grid-template: repeat(1, minmax(40px, 1fr)) / 1fr;
        }
     
        .FormEncuesta__bloque3-1columnas{
        display: grid;
        grid-template: repeat(1, minmax(40px, 1fr)) / 1fr;
        
        }
        .FormEncuesta button{
            width: 80px;
            height: 20px;
            font-size: 12px;
            
        }
        .FormEncuesta p{
            font-size: 14px;
            
        }
        .FormEncuesta__bloque2-columnas label{
            font-size: 12px;
        }
    
      }