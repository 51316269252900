/* Client card component */
.ClientCard {
  display: inline-block;
  margin: 0;
}

/* img of Client card component */
.ClientCard .image {
  /* filter: grayscale(100%); */
  widows:100px;
  height:auto;
}