.blog-section{
    /* display: grid;
    grid-template: 1fr / 1fr; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    /* margin-bottom: 25px; */
}
.blog-section__body{
    /* display: grid;
    grid-template: repeat(4,auto) / 600px 600px;
    padding-top: 5.5em;
    justify-content: center; */
    /* display: flex;
    flex-direction:row ;
    /* column-count: 2; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    justify-items:left;
    margin: 0 auto; 
}
.blog-section__body h1{
    text-align: left;
    font-size: 1.8vw;
    padding-top: 4%;
}
.blog-section-body__image-description{
    /* display: grid;
    width: 100%;
    grid-column: 1 / 3;
    grid-template: 1fr / 1fr */
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.blog-section-body__image-description__date{
    /* display: grid;
    grid-template: 1fr / 2% 1fr;
    justify-items: left;
    align-items: center; */
    display: flex;
    flex-direction: row wrap;
    justify-content: left;
    justify-content: center;
}
.blog-section__tittle{
    /* grid-column: 1 / 3; */
    display: flex;
    flex-direction: row wrap;
    justify-content: center;
}
.blog-section-body__description{
    /* font-size: 20px;
    margin: 0;
    text-align: justify;
    grid-row: 3 / 4;
    grid-column: 2 / 3;
    width: 100%; */
    display: flex;
    flex-direction: row wrap;
    justify-content: center;
}
.blog-section-body__button{
    background: #d92530;
    text-decoration: none;
    text-transform: uppercase;
    height: 20px;
    justify-self: center;
    border: none;
    align-self: end;
    grid-column: 2 / 3;
    color: white;
    grid-row: 3 / 4;
    padding: 16px 32px;
    font-size: 16px;
    transition-duration: 0.4s;
    cursor: pointer;
}
.blog-section-body__image-adapt{
    /* width: 95%;
    grid-row: 3 / 4;
    grid-column: 1 / 2; */
    display: flex;
    width: 400px;
    justify-content: center;
}
@media screen and (max-width: 1220px) {
    .blog-section{
        display: grid;
        grid-template: 1fr / 90%;
        justify-content: center;
    }
    .blog-section__body{
        display: grid;
        grid-template: repeat(4,auto) / 1150px;
        padding-top: 5.5em;
        justify-content: center;
        justify-items: left;
    }
    .blog-section__body h1{
        text-align: left;
        font-size: 2.8vw;
        padding-top: 2%;
    }
    .blog-section-body__image-description{
        display: grid;
        width: 100%;
        grid-column: 1 / 3;
        grid-template: 1fr / 1fr
    }
    .blog-section-body__image-description__date{
        display: grid;
        grid-template: 1fr / 4% 1fr;
        justify-items: left;
        align-items: center;
    }
    .blog-section__tittle{
        grid-column: 1 / 3;
    }
    .blog-section-body__description{
        font-size: 2.2vw;
        text-align: justify;
        grid-row: 4 / 5;
        grid-column: 1 / 3;
        width: 100%;
    }
    .blog-section-body__button{
        background: #d92530;
        border: #d92530 1px solid; 
        text-transform: uppercase;
        border: none;
        color: white;
        grid-column: 1 /2;
        grid-row: 5/6;
        padding: 16px 32px;
        font-size: 16px;
        -webkit-transition-duration: 0.4s;
        transition-duration: 0.4s;
        cursor: pointer;
    }
    .blog-section-body__image-adapt{
        width: 50%;
        grid-row: 3 / 4;
        grid-column: 1 / 3;
    }
}
@media screen and (max-width: 750px) {
    .blog-section__body{
        display: grid;
        grid-template: repeat(4,auto) / 100%;
        padding-top: 8.5em;
        justify-content: center;
        justify-items: left;
    }
    .blog-section-body__image-adapt{
        width: 100%;
        grid-row: 3 / 4;
        grid-column: 1 / 3;
    }
    .blog-section__body h1{
        text-align: left;
        font-size: 2em;
        padding-top: 2%;
    }
    .blog-section-body__image-description__date p{
        font-size: 12px;
    }
    .blog-section-body__description{
        font-size: 4.7vw;
        text-align: justify;
        grid-row: 4 / 5;
        grid-column: 1 / 3;
        width: 100%;
    }
    .blog-section-body__button{
        text-transform: uppercase;
        border: none;
        color: white;
        padding-right: 20px;
        padding-left: 20px;
        padding-top: 10px;
        padding-bottom: 10px;
        grid-column: 1 /2;
        grid-row: 5/6;
        transition-duration: 0.4s;
        cursor: pointer;
    }
}